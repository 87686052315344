import familyCareIcon from "../../../../assets/health/family-care-icon.svg";
import bhnIcon from "../../../../assets/health/bhn-icon.svg";
import easyCare from "../../../../assets/health/easy-care-icon.svg";
import tomato from "../../../../assets/health/tomato-icon.svg";
import logoDefaul from "../../../../assets/health/logo_defaul.svg";
import moment from "moment";

export const initialState = {
  curenPage: "get-infor",
  listInsuranceesCheck: [],
  listInsurances: [],
  listImgLicese: [],
  insuranceTypes: [],
  riskTypes: [],
  listBanks: [],
  arrayLinks: [],
  listImgRelationships: [],
  typeOfIndimnities: [],
  listImgShow: [],
  typeOfIndimnity: {},
  riskType: {},
  indexImgShow: {},
  errors: {},
  snackbar: {},
  insuredInfo: {},
  dataSignature: {},
  dataPrivateId: { typeId: 500, typeCode: "private_id" },
  extraInfo: {},
  picker: {},
  page: {},
  modalRule: {},
  linkDKDK: {},
  requestAmount: "",
  dialogNoti: {
    open: false,
    type: "warning",
  },
  accodiorInsuredInfor: true,
  accodiorBeneficiary: true,
  checkConditions: false,
  disableNextButton: true,
  curenStep: 0,
  beneficiary: "self",
  headerTitle: "Yêu cầu bồi thường",
  titleButtonBT: "Tiếp tục",
  insuredGender: "",
};

export const insurendOwnerInfo = [
  { title: "Họ và tên", name: "insuredName", capitalize: true },
  { title: "Năm sinh", name: "insuredDob" },
  { title: "Giới tính", name: "insuredGender" },
  {
    title: "CMND/CCCD/ Hộ chiếu",
    name: "insuredPrivateId",
    helperText: "Nhập số định danh của người được bào hiểm",
  },
  { title: "Số điện thoại", name: "insuredPhone", allowEdit: true },
  {
    title: "Email",
    name: "insuredEmail",
    helperText: "Nhập email để nhận thông báo bồi thường",
    allowEdit: true,
  },
];

export const relationships = [
  { title: "Vợ/ chồng", value: 2 },
  { title: "Con cái", value: 3 },
  { title: "Bố/ Mẹ", value: 4 },
];

export const formatDate = (date) => {
  if (!date) return "";
  const newDate = moment(date).format("DD/MM/YYYY");
  return newDate !== "Invalid date" ? newDate : "--";
};

export const convertCurrency = (money) => {
  money = !money ? "0" : money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return money;
};

export const renderRelationship = (id) => {
  switch (id) {
    case 1:
      return "Bản thân";
    case 2:
      return "Vợ/chồng";
    case 3:
      return "Con cái";
    default:
      return "Bản thân";
  }
};

export const typeOfIndimnity = [
  { title: "Nội trú", id: 1 },
  { title: "Ngoại trú", id: 2 },
  { title: "Thương tật vĩnh viễn", id: 3 },
  { title: "Thai sản", id: 4 },
  { title: "Bệnh hiểm nghèo hoặc ung thư", id: 5 },
  { title: "Tử vong", id: 6 },
];

export const listBeneficiaryInfo = [
  { title: "Chủ tài khoản", name: "beneficiaryName", capitalize: true },
  { title: "MQH với người được BH", name: "relationshipWithBuyerName" },
  { title: "Số tài khoản", name: "beneficiaryBankAcountNumber" },
  { title: "Ngân hàng", name: "beneficiaryBankTitle" },
  { title: "Chi nhánh", name: "beneficiaryBankBranchName" },
];

export const listTypeOfTreatments = [
  { title: "Khám thai định kỳ", value: 1, maternity: true },
  { title: "Sanh thường", value: 2, maternity: true },
  { title: "Sanh mổ", value: 3, maternity: true },
  { title: "Biến chứng thai sản", value: 4, maternity: true },
  { title: "Ung thư", value: 5, cancer: true },
  { title: "Đau tim/ Đột quỵ", value: 6, cancer: true },
  { title: "Bệnh thông thường", value: 12, died: true },
  { title: "Đau tim/ Đột quỵ", value: 13, died: true },
  { title: "Bệnh hiểm nghèo", value: 14, died: true },
];

export const getIconInsurance = (id) => {
  switch (id) {
    case 22:
      return { icon: familyCareIcon, color: "#069D27" };
    case 6:
      return { icon: bhnIcon, color: "#0B3C8A" };
    case 8:
      return { icon: easyCare, color: "#FF8200" };
    case 19:
      return { icon: tomato, color: "#E22028" };
    default:
      return { icon: logoDefaul, color: "#8FAFFF" };
  }
};

export const converDate = (string) => {
  if (!string) {
    return null;
  }
  if (string?.indexOf("T") > -1) {
    return string;
  } else {
    if (string.includes("/") || string.includes("-")) {
      const check_string = (string.match(/\//g) || []).length;
      const matches = (string.match(/(\d+)[-\/]/g) || []).map((match) =>
        match.slice(0, -1)
      );

      if (matches[0]?.length == 4) {
        return string;
      }

      return check_string > 0
        ? string.split("/").reverse().join("/")
        : string.split("-").reverse().join("-");
    }

    return null;
  }
};

export const getListLicenseById = ({
  insuranceCode,
  typeOfIndimnity,
  typeOfRisk = null,
}) => {
  if (!insuranceCode) return [];

  const getNewId = () => {
    switch (typeOfIndimnity) {
      case 1:
        return 7;
      case 2:
        return 8;
      case 3:
        return 9;
      case 6:
        return 10;
      default:
        return null;
    }
  };

  const newTypeOfIndimnity =
    insuranceCode == "tai_nan" ? getNewId() : typeOfIndimnity;
  return listLicense.reduce((array, item) => {
    const listIdItem = item.typeOfIndimnity.map((i) => i.id);
    const listIdOptional = (item.optional || []).map((i) => i.id);

    if (!typeOfRisk) {
      array = listIdItem.includes(newTypeOfIndimnity)
        ? [
            ...array,
            {
              title: item.title,
              typeId: item.typeId,
              typeCode: item.typeCode,
              nonMultiple: !!item.nonMultiple,
              optional: listIdOptional.includes(newTypeOfIndimnity),
            },
          ]
        : array;
    } else {
      const listTypeOfIndimnityTypeOfRisk =
        item.typeOfIndimnity?.find((i) => i.id == newTypeOfIndimnity)
          ?.typeOfRisk || [];
      const listTypeOfIndimnityOptional =
        item.optional?.find((i) => i.id == newTypeOfIndimnity)?.typeOfRisk ||
        [];

      array =
        listIdItem.includes(newTypeOfIndimnity) &&
        listTypeOfIndimnityTypeOfRisk.includes(typeOfRisk)
          ? [
              ...array,
              {
                title: item.title,
                typeId: item.typeId,
                typeCode: item.typeCode,
                nonMultiple: !!item.nonMultiple,
                optional:
                  listIdOptional.includes(newTypeOfIndimnity) &&
                  listTypeOfIndimnityOptional.includes(typeOfRisk),
              },
            ]
          : array;
    }

    return [...array];
  }, []);
};

//typeOfIndimnity id =
//1 : Nội trú
//2 : Ngoại trú
//3 : Thương tật vĩnh viễn
//4 : Thai sản
//5 :Bệnh hiểm nghèo/ ung thư
//6 : Tử vong
//7 : Nội trú (tai nạn)
//8 : Ngoại trú (tai nạn)
//9 : Thương tật vĩnh viễn (tai nạn)
//10 : Tử vong (tai nạn)

//typeOfRisk id =

//1 : Khám định kỳ
//2 : Sinh thường
//3 : Sinh mổ
//4 : Biến chứng thai sản
//5 :Ung thư
//6 : Đau tim/ đột quỵ
//7 : Súc vật cắn
//8 : Bỏng/ phỏng
//9 : Tai nạn sinh hoạt
//10 : Tai nạn lao động
//11 : Tai nạn giao thông
//12 : Bệnh thông thường
//13 : Đau tim, đột quỵ
//14 : Bệnh hiểm nghèo

// optional = true => Trường không bắt buộc

export const listLicense = [
  {
    title: "Biên bản tường trình",
    typeId: 537,
    typeCode: "report",
    typeOfIndimnity: [
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Tóm tắt bệnh án, báo cáo y tế, phiếu chỉ định khám",
    typeId: 531,
    typeCode: "summary_report_health",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 9, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 7, typeOfRisk: [7, 8] },
      { id: 9, typeOfRisk: [7, 8] },
    ],
  },
  {
    title: "Hồ sơ bệnh án",
    typeId: 502,
    typeCode: "medical_records",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
    ],
    optional: [
      { id: 1 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
    ],
  },
  {
    title: "Chỉ định xét nghiệm/ chụp XQ...và kết quả",
    typeId: 504,
    typeCode: "test_xq_and_result",
    typeOfIndimnity: [
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Toa thuốc",
    typeId: 507,
    typeCode: "prescription",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7] },
    ],
  },
  {
    title: "Phiếu thu chi phí khám",
    typeId: 525,
    typeCode: "examination_receipt",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Phiếu thu chi phí thuốc",
    typeId: 524,
    typeCode: "prescription_receipt",
    typeOfIndimnity: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [1, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Bảng kê chi phí điều trị",
    typeId: 506,
    typeCode: "treatment_cost_checkout",
    optional: true,
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [2, 3, 4] },
      { id: 5, typeOfRisk: [6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [8, 9, 10, 11] },
    ],
    optional: [
      { id: 2 },
      { id: 4, typeOfRisk: [4] },
      { id: 5, typeOfRisk: [6] },
      { id: 8, typeOfRisk: [8, 9, 10, 11] },
    ],
  },
  {
    title: "Hóa đơn thuốc",
    typeId: 509,
    typeCode: "medicine_bill",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Hóa đơn chi phí khám",
    typeId: 508,
    typeCode: "examination_cost_bill",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
      { id: 8, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Giấy chứng nhận phẫu thuật (nếu có)",
    typeId: 503,
    typeCode: "surgery_cert",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Kết qủa xét nghiệm và mô bệnh học",
    typeId: 513,
    typeCode: "test_result_and_histopathology",
    typeOfIndimnity: [
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [14] },
    ],
    optional: [
      { id: 5, typeOfRisk: [6] },
      { id: 6, typeOfRisk: [14] },
    ],
  },
  {
    title: "Kết quả chẩn đoán hình ảnh, tế bào học",
    typeId: 514,
    typeCode: "diagnose_image_cytology",
    typeOfIndimnity: [
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [14] },
    ],
    optional: [
      { id: 5, typeOfRisk: [6] },
      { id: 6, typeOfRisk: [14] },
    ],
  },
  {
    title: "Giấy ra viện",
    typeId: 510,
    typeCode: "hospital_checkout",
    typeOfIndimnity: [
      { id: 1 },
      { id: 3 },
      { id: 4, typeOfRisk: [2, 3] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 7, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
    optional: [{ id: 3 }, { id: 5, typeOfRisk: [5, 6] }],
  },
  {
    title: "Chi phí tái khám sau xuất viện, đơn thuốc",
    typeId: 511,
    typeCode: "re-examination_cost",
    typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3] }],
    optional: [{ id: 4, typeOfRisk: [2, 3] }],
  },
  {
    title: "Hóa đơn tái khám sau xuất viện, đơn thuốc",
    typeId: 512,
    typeCode: "re-examination_bill",
    typeOfIndimnity: [{ id: 4, typeOfRisk: [2, 3, 4] }],
    optional: [{ id: 4, typeOfRisk: [2, 3, 4] }],
  },
  {
    title: "Giấy chứng tử/ trích lục khai tử",
    typeId: 515,
    typeCode: "death_cert",
    typeOfIndimnity: [
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 10, typeOfRisk: [7, 8, 9, 10, 11] },
    ],
  },
  {
    title: "Phiếu chích ngừa/ chỉ định chích ngừa/ sổ theo dõi chích ngừa",
    typeId: 528,
    typeCode: "paper_suggestion_book_tracking_of_vaccine",
    typeOfIndimnity: [{ id: 8, typeOfRisk: [7] }],
  },
  {
    title:
      "Tường trình về tình huống và nguyên nhân tử vong của người được bảo hiểm",
    typeId: 527,
    typeCode: "situation_&_cause_of_death",
    typeOfIndimnity: [{ id: 6, typeOfRisk: [12, 13, 14] }],
    optional: [{ id: 6, typeOfRisk: [12, 13, 14] }],
  },
  {
    title: "Giấy chứng nhận thương tật của cơ quan có thẩm quyền",
    typeId: 532,
    typeCode: "certificate_of_injury",
    typeOfIndimnity: [{ id: 3 }, { id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Xác nhận của chính quyền đại phương hoặc cơ quan chủ quản hoặc bệnh viện có kết luận nguyên nhân",
    typeId: 533,
    typeCode: "certificate_of_reason",
    typeOfIndimnity: [{ id: 9, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Biên bản công an điều tra có kết luận nguyên nhân tử vong/ biên bản tai nạn có xác nhận của chính quyền đại phương hoặc cơ quan chủ quản. Bảo cáo y tế/ hồ sơ bệnh án của bệnh viện có kết luận nguyên nhân tử vong",
    typeId: 534,
    typeCode: "death_investigation_report_from_police",
    typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Biên bản phân chia di sản thừa kế có xác nhận của cơ quan có thẩm quyền",
    typeId: 535,
    typeCode: "division_of_inheritance",
    typeOfIndimnity: [{ id: 10, typeOfRisk: [7, 8, 9, 10, 11] }],
  },
  {
    title:
      "Giấy phép lái xe nếu tai nạn khi đang khi đang điều khiển xe trên 50 cc",
    typeId: 536,
    typeCode: "driving_licence",
    typeOfIndimnity: [
      { id: 7, typeOfRisk: [11] },
      { id: 8, typeOfRisk: [11] },
      { id: 9, typeOfRisk: [11] },
      { id: 10, typeOfRisk: [11] },
    ],
    optional: [
      { id: 7, typeOfRisk: [11] },
      { id: 8, typeOfRisk: [11] },
      { id: 9, typeOfRisk: [11] },
      { id: 10, typeOfRisk: [11] },
    ],
  },
  {
    title: "Khác",
    typeId: 523,
    typeCode: "other",
    typeOfIndimnity: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10] },
      { id: 8, typeOfRisk: [7, 8, 9, 10] },
      { id: 9, typeOfRisk: [7, 8, 9, 10] },
      { id: 10, typeOfRisk: [7, 8, 9, 10] },
    ],
    optional: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4, typeOfRisk: [1, 2, 3, 4] },
      { id: 5, typeOfRisk: [5, 6] },
      { id: 6, typeOfRisk: [12, 13, 14] },
      { id: 7, typeOfRisk: [7, 8, 9, 10] },
      { id: 8, typeOfRisk: [7, 8, 9, 10] },
      { id: 9, typeOfRisk: [7, 8, 9, 10] },
      { id: 10, typeOfRisk: [7, 8, 9, 10] },
    ],
  },
];
