import React, { useEffect } from "react";
import { FormControlLabel, MenuItem, Autocomplete, Box } from "@mui/material";
import { isEmpty } from "lodash";

import { styledInfo } from "../components/styeleds/styledInfor";
import { SteperHeader, CategoryLicense } from "../components";
import { TextField, Checkbox } from "../overrideMui";
import { converDate, insurendOwnerInfo, relationships } from "../utils";
import moment from "moment";

export default function Infor(props) {
  const { state, dispatch, isApp } = props;
  const classes = styledInfo();
  const {
    curenStep,
    beneficiary,
    insuredInfo,
    listBanks,
    errors,
    listImgRelationships,
    listInsuranceesCheck,
    insuredGender,
    insuranceType,
    typeOfIndimnity,
  } = state;

  const insuredInforUser = listInsuranceesCheck[0] || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isEmpty(listBanks)) {
      dispatch("getListBanks");
    }
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        minHeight: isApp ? "calc(100vh - 175px)" : "calc(100vh - 130px)",
      }}
    >
      <SteperHeader activeStep={curenStep} insuranceType={insuranceType} />
      <div className={classes.title}>Thông tin người được bảo hiểm</div>
      {insurendOwnerInfo.map((item, index) =>
        item.name === "insuredGender" &&
        ![0, 1].includes(insuredInforUser?.insuredInfo?.insuredGender) ? (
          <>
            <div
              style={{ marginBottom: "8px", color: "#4B4B4B" }}
              key={`ttndbh_${item.title}`}
            >
              {item.title} <span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              key={`ttbh_${index}`}
              fullWidth
              select
              error={errors.insuredGender}
              helperText={
                errors.insuredGender ? "Vui lòng chọn giới tính" : false
              }
              onChange={(e) =>
                dispatch({
                  type: "onchangeInsuredGender",
                  value: e.target.value,
                })
              }
              className={`${classes.unDisabledTextField}`}
              value={insuredGender}
            >
              {[0, 1].map((i) => (
                <MenuItem key={i} value={i}>
                  {i === 1 ? "Nam" : i === 0 ? "Nữ" : ""}
                </MenuItem>
              ))}
            </TextField>
          </>
        ) : (
          <>
            <div
              style={{ marginBottom: "8px", color: "#4B4B4B" }}
              key={`ttndb_2_${item.title}`}
            >
              {item.title}{" "}
              {item.name != "insuredEmail" && (
                <span style={{ color: "red" }}>*</span>
              )}
            </div>
            <TextField
              key={`ttbh_2_${index}`}
              fullWidth
              placeholder="Nhập thông tin"
              helperText={item?.helperText || false}
              sx={{ textTransform: item.capitalize ? "capitalize" : "" }}
              className={
                item.capitalize
                  ? `${classes.textFieldCapitalize}`
                  : "" + insuredInforUser?.insuredInfo[item.name]
                  ? `${classes.disabledTextField}`
                  : `${classes.unDisabledTextField}`
              }
              onChange={(e) =>
                dispatch({
                  type: "onChangeBeneficiary",
                  value: e.target.value,
                  name: item.name,
                })
              }
              value={
                item.name === "insuredDob"
                  ? moment(
                      converDate(insuredInforUser?.insuredInfo?.insuredDob)
                    ).format("DD/MM/YYYY")
                  : item.name === "insuredGender"
                  ? insuredInforUser?.insuredInfo[item.name] == 0
                    ? "Nữ"
                    : "Nam"
                  : ["insuredEmail", "insuredPhone"].includes(item.name)
                  ? insuredInfo[item.name] || ""
                  : insuredInforUser?.insuredInfo[item.name] ||
                    insuredInfo[item.name] ||
                    ""
              }
              disabled={
                item.allowEdit
                  ? false
                  : !!insuredInforUser?.insuredInfo[item.name]
              }
            />
          </>
        )
      )}
      {/* <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>Email</div>
      <TextField
        fullWidth
        className={classes.disabledTextField}
        value="name"
        disabled
      /> */}
      <div className={classes.title}>Người thụ hưởng</div>
      <div className={classes.textBlu}>
        Lưu ý tài khoản ngân hàng này là tài khoản của người được bảo hiểm hoặc
        người thừa kế của người được bảo hiểm
      </div>
      <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>
        Chọn người thụ hưởng
      </div>
      <div className={classes.choosPerson} style={{ marginBottom: "8px" }}>
        <FormControlLabel
          className="self"
          control={
            <Checkbox
              checked={beneficiary === "self"}
              disabled={typeOfIndimnity?.id == 6}
              onChange={(e) => {
                dispatch({
                  type: "oncheckBeneficiary",
                  name: "self",
                  value: e.target.checked,
                });
              }}
            />
          }
          label="Bản thân"
        />
        <FormControlLabel
          className="relative"
          control={
            <Checkbox
              checked={beneficiary === "relative"}
              onChange={(e) => {
                dispatch({
                  type: "oncheckBeneficiary",
                  name: "relative",
                  value: e.target.checked,
                });
              }}
            />
          }
          label="Người thân"
        />
      </div>
      <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>
        Họ và tên người thụ hưởng <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        fullWidth
        value={
          beneficiary === "self"
            ? insuredInforUser?.insuredInfo?.insuredName
            : insuredInfo?.beneficiaryName || ""
        }
        error={beneficiary === "self" ? false : errors.beneficiaryName}
        helperText={
          beneficiary === "relative" &&
          errors.beneficiaryName &&
          "Vui lòng nhập đầy đủ thông tin"
        }
        className={`${classes.textField} ${classes.textFieldCapitalize}`}
        InputProps={{ readOnly: beneficiary === "self" }}
        onChange={(e) =>
          dispatch({
            type: "onChangeBeneficiary",
            value: e.target.value,
            name: "beneficiaryName",
          })
        }
      />
      {beneficiary === "relative" && (
        <>
          <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>
            Mối quan hệ với người được bảo hiểm
            <span style={{ color: "red" }}>*</span>
          </div>
          <TextField
            fullWidth
            select
            error={
              beneficiary === "self" ? false : errors.relationshipWithBuyerId
            }
            helperText={
              beneficiary === "relative" &&
              errors.relationshipWithBuyerId &&
              "Vui lòng chọn đầy đủ thông tin"
            }
            value={insuredInfo?.relationshipWithBuyerId || ""}
            className={classes.textField}
            onChange={(e) =>
              dispatch({
                type: "onChangeBeneficiary",
                value: e.target.value,
                name: "relationshipWithBuyerId",
              })
            }
          >
            {relationships.map((item, i) => (
              <MenuItem value={+item.value} key={i}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
      <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>
        Số tài khoản <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        fullWidth
        value={insuredInfo?.beneficiaryBankAcountNumber || ""}
        className={classes.textField}
        error={errors?.beneficiaryBankAcountNumber}
        helperText={
          errors.beneficiaryBankAcountNumber
            ? "Vui lòng chọn đầy đủ thông tin"
            : false
        }
        onChange={(e) =>
          dispatch({
            type: "onChangeBeneficiary",
            value: e.target.value,
            name: "beneficiaryBankAcountNumber",
          })
        }
      />
      <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>
        Chọn ngân hàng <span style={{ color: "red" }}>*</span>
      </div>
      {/* <TextField fullWidth value="name" className={classes.textField} /> */}

      <div className={classes.inputDiv} key={"Autocomplete"}>
        <Autocomplete
          disablePortal
          // key={index}
          id="combo-box-demo"
          options={listBanks}
          value={insuredInfo?.bank || null}
          sx={{ width: "100%" }}
          getOptionLabel={(option) => option?.short_name || ""}
          noOptionsText={"Không tìm thấy dữ liệu"}
          filterOptions={(option, state) => {
            const { inputValue } = state;
            const newArray = [];
            option.forEach((item) => {
              if (
                item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                item.short_name.toLowerCase().includes(inputValue.toLowerCase())
              ) {
                newArray.push(item);
              }
            });
            return newArray;
          }}
          renderOption={(props, option) => (
            <Box
              style={{ paddingLeft: "6px" }}
              component="li"
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                paddingLeft: "6px",
              }}
              {...props}
            >
              <div className={classes.bankImg}>
                <img
                  loading="lazy"
                  style={{ width: "100%", height: "100%" }}
                  src={option.url}
                  alt={option.short_name}
                />
              </div>
              <div className={classes.bankOption}>
                <span className="short-name">{option.short_name}</span>
                <span className="name">{option.name}</span>
              </div>
            </Box>
          )}
          onChange={(e, data) =>
            dispatch({
              type: "onChangeBeneficiary",
              name: "bank",
              value: data,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              className={classes.textAuto}
              error={errors.bank}
              helperText={
                errors.bank ? "Vui lòng chọn đầy đủ thông tin" : false
              }
            />
          )}
        />
        {/* <div className="shrink" key={item.label}>
          {item.label} <span style={{ color: "red" }}>*</span>
        </div> */}
      </div>

      <div style={{ marginBottom: "8px", color: "#4B4B4B" }}>
        Chi nhánh <span style={{ color: "red" }}>*</span>
      </div>
      <TextField
        fullWidth
        value={insuredInfo?.beneficiaryBankBranchName || ""}
        className={classes.textField}
        onChange={(e) =>
          dispatch({
            type: "onChangeBeneficiary",
            value: e.target.value,
            name: "beneficiaryBankBranchName",
          })
        }
        error={errors.beneficiaryBankBranchName}
        helperText={
          errors.beneficiaryBankBranchName
            ? "Vui lòng nhập đầy đủ thông tin"
            : false
        }
      />
      {beneficiary === "relative" && (
        <CategoryLicense
          error={!!errors["517"]}
          title={
            "Các giấy tờ chứng minh quan hệ giữa người được bảo hiểm (CMND/CCCD, Giấy kết hôn, Giấy khai sinh, Văn bản ủy uyền...)"
          }
          listImgs={listImgRelationships || []}
          addImg={(e) => {
            dispatch({ type: "addImg", e, typeId: 517 });
            e.target.value = null;
          }}
          deleteImg={({ e, index, url }) =>
            dispatch({
              type: "deleteImgRelationships",
              index,
              url,
            })
          }
        />
      )}
    </div>
  );
}
