import React, { useEffect, useLayoutEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ThemeProvider, createTheme } from "@mui/material";

import styleClaim from "./styleClaim";

export default function BeneficiaryInformation(props) {
  const classes = styleClaim();
  const { state } = props;
  const {
    beneficiaryInformationExpan,
    insuredPersonInformation,
    dialogUpdate,
  } = state;

  const renderData = [
    {
      title: "Họ và tên",
      value: insuredPersonInformation?.beneficiaryName,
      color: "#3961A0",
      capitalize: true,
    },
    {
      title: "Quan hệ với chủ HĐ",
      value:
        insuredPersonInformation?.relationshipWithInsuredPersonId == 1
          ? "Bản Thân"
          : insuredPersonInformation?.relationshipWithInsuredPersonId == 2
          ? "Người Thân"
          : "",
      color: "#3961A0",
    },
    {
      title: "Số tài khoản",
      value: insuredPersonInformation?.beneficiaryBankAcountNumber,
      color: "#3961A0",
    },
    {
      title: "Ngân hàng",
      value:
        insuredPersonInformation?.beneficiaryBank ||
        insuredPersonInformation?.beneficiaryBankTitle,
      color: "#3961A0",
    },
    {
      title: "Chi nhánh",
      value: insuredPersonInformation?.beneficiaryBankBranchName,
      color: "#686868",
    },
  ];

  useEffect(() => {
    document.getElementById("box-beneficiary-information-div").style.height =
      beneficiaryInformationExpan
        ? 0
        : `${
            document
              ?.getElementById("box-beneficiary-information")
              ?.getBoundingClientRect()?.height + 38
          }px`;
  }, [dialogUpdate?.open, beneficiaryInformationExpan]);

  useLayoutEffect(() => {
    function updateSize() {
      document.getElementById("box-beneficiary-information-div").style.height =
        beneficiaryInformationExpan
          ? 0
          : `${
              document
                ?.getElementById("box-beneficiary-information")
                ?.getBoundingClientRect()?.height + 38
            }px`;
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [dialogUpdate?.open, beneficiaryInformationExpan]);

  return (
    <div
      className={classes.container_profile_information}
      id="box-beneficiary-information-div"
      style={{
        opacity: beneficiaryInformationExpan ? 0 : 1,
        padding: beneficiaryInformationExpan ? 0 : "",
        margin: beneficiaryInformationExpan ? "8px 0" : "",
        MozTransition: "all 0.7s ease",
        WebkitTransition: "all 0.7s ease",
        OTransition: "all 0.7s ease",
        transition: "all 0.7s ease",
      }}
    >
      <ThemeProvider
        theme={createTheme({
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 900,
              lg: 1200,
              xl: 1536,
              xxl: 2250,
            },
          },
        })}
      >
        <Grid container spacing={2} id="box-beneficiary-information">
          {renderData.map((item, index) => (
            <Grid
              item
              key={index}
              xs={4}
              sm={2.4}
              md={2.4}
              lg={2.4}
              xl={3}
              xxl={2}
            >
              <Grid item key={index} xs={6} sm={4} md={4} lg={3} xl={3} xxl={2}>
                <div className="item-grid-profile-information">
                  <div className="lable">{item.title}</div>
                  <div
                    style={{
                      color: "#686868",
                      fontWeight: "600",
                      textTransform: item.capitalize ? "capitalize" : "",
                    }}
                  >
                    {item.value}
                  </div>
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    </div>
  );
}
