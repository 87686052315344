import _, { forEach } from "lodash";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";

import {
  getDetailClaim,
  getPackageBenefitList,
  additionalRequest,
  fileLicenseClaim,
  requestApprove,
  uploadImg,
  getHistoryClaim,
  approve,
  addNote,
  getDetailInsurance,
  complete,
  reject,
  getFileByUrl,
  getListConfig,
  updateDetail,
  invalidPDF,
  reconfirm,
  deletePDF,
  cancelPDF,
  deleteS3Document,
} from "../../../../../apis/health";
import {
  getListLicenseById,
  listTypeOfRisk,
  listLicense as listLicenseUnit,
  sortRowBenfit,
  checkBenefitFeedApprove,
} from "../utils";
import ExportClaimPDF from "../components/claimLicense/dialogSendApproval/ExportClaimPDF";
import Resizer from "react-image-file-resizer";
import { getParamUrlByKey } from "../../../../../utils/utils";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      650,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const converToPNG = (files) =>
  new Promise(async (resolve) => {
    const blobImg = new Blob([files], { type: files.type });
    const image = new Image();
    image.src = URL.createObjectURL(blobImg);
    await image.decode();
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    // canvas.width = image.width > 650 ? 650 : image.width;
    // canvas.height = image.height > 800 ? 800 : image.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0);

    canvas.toBlob((pngBlob) => {
      resolve(pngBlob);
    }, "image/png");
  });

export default function recordsDetailReducer({
  state,
  dispatch,
  action,
  id,
  level_id,
  systemType,
}) {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  // const roleAdminMenuItem =
  //   JSON.parse(localStorage.getItem("roleAdminMenuItem")) || {};
  const product_parents_from_user = _.get(
    user,
    "role_management_claim.product_parents",
    []
  );
  const product_id_parent = getParamUrlByKey("product_id_parent");
  const userTypeFromProfile = _.get(user, "extra_info.userType") || "";

  // const approve_from = roleAdminMenuItem?.role_management?.approve_from;
  const approve_to =
    product_parents_from_user.find(
      (i) => i?.product_id_parent === product_id_parent
    )?.approve_to || null;

  const cases = {
    //* run useEffect file RecodrsIndemnifyDetail
    initialDetail: async () => {
      const res = {};
      const [dataApi, listConfigApi] = await Promise.all([
        getDetailClaim(systemType, id),
        getListConfig(),
      ]);

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
        };
      }

      let fees = {};
      if (dataApi?.data?.statusCode === 200) {
        const temp = _.get(dataApi, "data.result") || {};

        // const isHasRelationship = !!temp?.elements?.find(
        //   (i) => i.type_id === 517
        // );

        const itemNoteUseL1 = (temp?.elements || []).find(
          (i) => i.type_id === 521
        );

        res.detail = temp;
        res.rowsBeneficiary =
          res.detail?.insuredInfo instanceof Object
            ? [temp.insuredInfo]
            : temp.insuredInfo || [];

        res.insuredPersonInformation = {
          ...temp.insuredInfo,
          ...temp.extraInfo,
        };
        // res.rowsDiagnosis = temp?.extraInfo?.doctorDiagnoses || [];
        // res.rowsExamination = temp?.extraInfo?.examinationFees || [];
        // res.rowsBills = temp?.extraInfo?.bills || [];
        // res.rowsMedicines = temp?.extraInfo?.medicines || [];
        // res.rowsOtherBenefits = temp?.extraInfo?.others || [];
        // fees = getTotalFees({
        //   rowsExamination: res.rowsExamination || [],
        //   rowsBills: res.rowsBills || [],
        //   rowsMedicines: res.rowsMedicines || [],
        //   rowsOtherBenefits: res.rowsOtherBenefits || [],
        // });
        res.listNote = (temp?.elements || []).filter(
          (i) =>
            (i.type_id == 522 && i.type_code == "note") ||
            (i.type_id == 520 && i.status_id == 8)
        );
        res.noteUseLV1 = {
          note: _.get(itemNoteUseL1, "text") || "",
          approvedPercent: "",
          amount: "",
        };
        res.rowsHistoryTable1 = (temp.elements || []).filter((item) => {
          return (
            item.type_id == 521 &&
            [2, 3, 5, 7, 8, 9, 10].includes(item.status_id) &&
            !!(_.get(item, "extra_info.documents[0].path") || null)
          );
        });

        await dispatch("getPackageBenefitList", { detail: res.detail });
      }
      const insuranceTypes = _.get(listConfigApi, "data.result") || [];

      if (level_id > 1) {
        res.tabsDetail = { key: "recordkeeping-talbe", value: 4 };
        res.tabRight = 1;
      }

      return { ...res, loading: false, ...fees, insuranceTypes };
    },

    //* get list package benefit from file ContractInfomation
    getPackageBenefitList: async () => {
      const { detail } = state;
      const param = {
        // productId: detail.productId,
        isUsed: 1,
        certNum: detail.certNum,
        privateId: _.get(detail, "insuredInfo.insuredPrivateId", "") || null,
        productPackageId: detail.productPackageId,
        productIdParent: detail.productIdParent,
        limit: 1000,
        offset: 0,
      };
      dispatch("onLoading");
      const res = {};
      const dataApi = await getPackageBenefitList(param);

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        const dataRows = sortRowBenfit(dataApi?.data?.result?.data || []);

        // const sortedRow = sortRowBenfit(dataRows);

        const code_benefit_already = [];

        res.rowsInsuranceBenefit = dataRows.reduce((array, item) => {
          if (code_benefit_already.includes(item.code_benefit)) {
            return array;
          }

          const itemPartds = item.code_benefit.split(".");
          const level = itemPartds?.length;

          if (level === 1) {
            item.isFirst = true;
            code_benefit_already.push(item.code_benefit);
            const parentItem = dataRows.filter((i) =>
              i?.code_benefit?.includes(itemPartds[0])
            );

            let sum = 0;
            parentItem.forEach((j) => {
              sum = sum + +(j?.amount_is_used || 0);
            });
            const new_value_lv1 =
              (+item?.config_benefit?.value || 0) - sum < 0
                ? 0
                : (+item?.config_benefit?.value || 0) - sum;
            // const new_value_lv1 =
            //   (+item?.config_benefit?.value || 0) -
            //     +item?.amount_is_used -
            //     sum <
            //   0
            //     ? 0
            //     : (+item?.config_benefit?.value || 0) -
            //       +item?.amount_is_used -
            //       sum;

            item.newValue = new_value_lv1;
            const childItems = [];

            const itemsLeve_2 = parentItem.filter((y) => {
              const codeLevels = y.code_benefit.split(".");
              return codeLevels.length === 2;
            });
            itemsLeve_2.forEach((item_lv2) => {
              code_benefit_already.push(item_lv2.code_benefit);

              const itemsLeve_3 = parentItem.filter((z) => {
                const codeLevels = z.code_benefit.split(".");
                return (
                  codeLevels.length === 3 &&
                  z.code_benefit.includes(item_lv2?.code_benefit)
                );
              });
              let sum_lv2 = 0;
              itemsLeve_3.forEach((f) => {
                sum_lv2 = sum_lv2 + (+f.amount_is_used || 0);
              });

              const new_value_lv2 =
                (+item_lv2?.config_benefit?.value || 0) -
                  (+item_lv2?.amount_is_used || 0) -
                  sum_lv2 >
                0
                  ? (+item_lv2?.config_benefit?.value || 0) -
                    (+item_lv2?.amount_is_used || 0) -
                    sum_lv2
                  : 0;

              const new_item_lv2 = {
                ...item_lv2,
                isSecond: true,
                newValue:
                  new_value_lv2 > new_value_lv1 ? new_value_lv1 : new_value_lv2,
              };

              childItems.push(new_item_lv2);

              itemsLeve_3.forEach((x) => {
                code_benefit_already.push(x.code_benefit);
                childItems.push({
                  ...x,
                  newValue:
                    (+x?.config_benefit?.value || 0) -
                      (+x?.amount_is_used || 0) >
                    new_item_lv2?.newValue
                      ? new_item_lv2?.newValue
                      : (+x?.config_benefit?.value || 0) -
                        (+x?.amount_is_used || 0),
                });
              });
            });

            return [...array, item, ...childItems];
          }

          if (level === 2) {
            code_benefit_already.push(item.code_benefit);
            const itemsLeve_3 = dataRows.filter((i) => {
              const codeLevels = i.code_benefit.split(".");
              return (
                codeLevels.length === 3 &&
                i.code_benefit.includes(item?.code_benefit)
              );
            });

            let sum_lv2 = 0;
            itemsLeve_3.forEach((f) => {
              sum_lv2 = sum_lv2 + (+f.amount_is_used || 0);
            });
            const childItems_lv2 = [];
            const new_item_lv2_2 = {
              ...item,
              isSecond: true,
              newValue:
                (+item?.config_benefit?.value || 0) -
                  +item?.amount_is_used -
                  sum_lv2 <
                0
                  ? 0
                  : (+item?.config_benefit?.value || 0) -
                    +item?.amount_is_used -
                    sum_lv2,
            };

            itemsLeve_3.forEach((x) => {
              code_benefit_already.push(x.code_benefit);
              childItems_lv2.push({
                ...x,
                newValue:
                  (+x?.config_benefit?.value || 0) - (+x?.amount_is_used || 0) >
                  new_item_lv2_2?.newValue
                    ? new_item_lv2_2?.newValue
                    : (+x?.config_benefit?.value || 0) -
                      (+x?.amount_is_used || 0),
              });
            });

            return [...array, new_item_lv2_2, ...childItems_lv2];
          }

          if (level === 3) {
            const new_item_lv3 = {
              ...item,
              newValue:
                (+item?.config_benefit?.value || 0) - +item?.amount_is_used < 0
                  ? 0
                  : (+item?.config_benefit?.value || 0) - +item?.amount_is_used,
            };

            return [...array, new_item_lv3];
          }
        }, []);
      }

      return { ...res, loading: false };
    },

    //* chang data in tabs header
    onChangeTabs: () => {
      const { value } = action;
      const { tabsDetail } = state;

      const key = () => {
        switch (value) {
          case 0:
            return "contract_infomation";
          case 1:
            return "lincense";
          case 2:
            return "claim";
          case 3:
            return "claim-license";
          case 4:
            return "recordkeeping-talbe";
          case 5:
            return "payment";
          default:
            return "";
        }
      };

      return { tabsDetail: { ...tabsDetail, value: value, key: key() } };
    },

    //* chang Expand to open or close accodions in file License
    onChangExpand: () => {
      const { name } = action;
      const { expandAccordions } = state;
      expandAccordions[name] = !expandAccordions[name];
      return { expandAccordions };
    },

    //* run useEffect file License
    initialLincense: () => {
      const { detail, listCommentLinceseAdd } = state;

      const isHasRelationship = !!detail?.elements?.find(
        (i) => i.type_id === 517
      );

      const listTypeIdLicese = isHasRelationship
        ? [
            ...getListLicenseById({
              typeOfInsurance: detail?.extraInfo?.typeOfInsurance,
              typeOfIndimnity: detail?.extraInfo?.typeOfIndimnity,
              typeOfRisk:
                detail?.extraInfo?.typeOfInsurance == 1
                  ? detail?.extraInfo?.typeOfRisk
                  : detail?.extraInfo?.typeOfTreatment,
            }),
            {
              title: "Chứng từ chứng minh quan hệ",
              typeId: 517,
              typeCode: "relationship",
            },
            {
              title: "Link hóa đơn điện tử",
              typeId: 516,
              typeCode: "e-invoice",
            },
          ]
        : [
            ...getListLicenseById({
              typeOfInsurance: detail?.extraInfo?.typeOfInsurance,
              typeOfIndimnity: detail?.extraInfo?.typeOfIndimnity,
              typeOfRisk:
                detail?.extraInfo?.typeOfInsurance == 1
                  ? detail?.extraInfo?.typeOfRisk
                  : detail?.extraInfo?.typeOfTreatment,
            }),
            {
              title: "Link hóa đơn điện tử",
              typeId: 516,
              typeCode: "e-invoice",
            },
          ];
      const listTypeId = listLicenseUnit.map((i) => i.typeId);
      const listTypeIdCurrent = listTypeIdLicese?.map((i) => i.typeId);
      detail?.elements.forEach((i) => {
        if (
          listTypeId.includes(i?.type_id) &&
          !listTypeIdCurrent.includes(i?.type_id)
        ) {
          const temp = listLicenseUnit.find(
            (license) => license.typeId == i?.type_id
          );
          listTypeIdCurrent.push(temp.typeId);
          listTypeIdLicese.push({
            title: temp.title,
            typeId: temp.typeId,
            typeCode: temp.typeCode,
          });
        }
      });

      const listCmt = (
        detail?.elements.filter(
          (i) => i.type_id === 520 && i?.extra_info?.elements_amend?.length > 0
        ) || []
      )
        ?.reverse()
        ?.map((item) => {
          const comments = item?.extra_info?.elements_amend?.map((cmt) => ({
            typeId: cmt?.typeId,
            text: cmt?.text,
            typeCode: cmt?.typeCode,
            title:
              listLicenseUnit?.find((lc) => lc.typeId === cmt?.typeId)?.title ||
              "",
          }));

          return { ...item, comments };
        });

      const newCmt = listCommentLinceseAdd.filter((i) => i.isNewCmt) || [];
      const itemEleme = (detail?.elements || []).find((i) => {
        let checkImg = false;
        (i?.extra_info?.documents || []).forEach((i) => {
          if (!!i?.path) {
            checkImg = true;
            return;
          }
        });

        return (
          i?.extra_info?.documents?.length > 0 &&
          ![516, 518, 520, 521].includes(i.type_id) &&
          !!checkImg
        );
      });

      const itemElemes = (detail?.elements || []).find(
        (i) => i.type_code == itemEleme?.type_code
      );
      // const documentsItemElemes = [];
      // itemElemes.forEach((i) => {
      //   (i?.extra_info?.documents || []).forEach((doc) => {
      //     doc?.path && documentsItemElemes.push(doc);
      //   });
      // });

      const temp = listLicenseUnit.find(
        (i) => i.typeCode == itemEleme?.type_code
      );
      // const listLicese = documentsItemElemes;
      const listLicese = itemElemes
        ? itemElemes?.extra_info?.documents || []
        : [];
      const imgLicese = {
        ...((itemEleme?.extra_info?.documents || []).find((i) => !!i?.path) ||
          {}),
        indexImg: 0,
        createdDate: itemEleme.created_date,
        nameLicense: temp?.title,
        typeId: itemEleme?.type_id,
      };
      const typeIdLiceseButton = itemEleme?.type_id;

      return {
        listCommentLinceseAdd: [...listCmt, ...newCmt],
        listLicese,
        imgLicese,
        listTypeIdLicese,
        typeIdLiceseButton,
      };
    },

    //* set open dialog claim info in page lincense
    setOpenDialogClaimInfo: () => {
      const { dialogClaimInfo, detail, insuranceTypes } = state;

      if (!dialogClaimInfo.open) {
        dialogClaimInfo.insuranceType =
          detail?.extraInfo?.typeOfInsurance || "";

        dialogClaimInfo.typeOfIndimnitys =
          insuranceTypes.find((i) => i.id == detail?.extraInfo?.typeOfInsurance)
            ?.typeOfIndimnity || [];

        dialogClaimInfo.typeOfIndimnity =
          detail?.extraInfo?.typeOfIndimnity || "";

        dialogClaimInfo.typeOfRisks =
          detail?.extraInfo?.typeOfInsurance == 1
            ? dialogClaimInfo.typeOfIndimnitys.find(
                (i) => i.id == detail?.extraInfo?.typeOfIndimnity
              )?.typeOfRisk || []
            : listTypeOfRisk.filter(
                (i) => i.parentId == detail?.extraInfo?.typeOfIndimnity
              );

        dialogClaimInfo.typeOfRisk =
          detail?.extraInfo?.typeOfRisk ||
          detail?.extraInfo?.typeOfTreatment ||
          "";
      }

      return {
        dialogClaimInfo: { ...dialogClaimInfo, open: !dialogClaimInfo.open },
      };
    },

    //* to change input and select in DialogClaimInfo
    onChangDialogClaimInfo: () => {
      const { value, name } = action;
      const { dialogClaimInfo, insuranceTypes, errors } = state;
      dialogClaimInfo[name] = value;
      errors[name] = false;

      if (name === "insuranceType") {
        dialogClaimInfo.typeOfIndimnitys =
          insuranceTypes.find((i) => i.id == value)?.typeOfIndimnity || [];
        dialogClaimInfo.typeOfIndimnity = "";
        dialogClaimInfo.typeOfRisks = [];
        dialogClaimInfo.typeOfRisk = "";
      }

      if (name === "typeOfIndimnity") {
        dialogClaimInfo.typeOfRisks =
          dialogClaimInfo.insuranceType == 2
            ? listTypeOfRisk.filter(
                (i) => i.parentId === dialogClaimInfo.typeOfIndimnity
              )
            : dialogClaimInfo.typeOfIndimnitys.find(
                (i) => i.id === dialogClaimInfo.typeOfIndimnity
              ).typeOfRisk || [];
        dialogClaimInfo.typeOfRisk = "";
        dialogClaimInfo.typeOfTreatment = "";
      }

      if (name === "typeOfRisk") {
        dialogClaimInfo.typeOfRisk =
          dialogClaimInfo.insuranceType == 1 ? value : null;
        dialogClaimInfo.typeOfTreatment =
          dialogClaimInfo.insuranceType == 2 ? value : null;
      }

      return { dialogClaimInfo, errors };
    },

    //* submit to update detail
    updateDetail: async () => {
      const { dialogClaimInfo, detail, snackbar, errors } = state;

      const validate = [];
      !dialogClaimInfo.insuranceType && validate.push("insuranceType");
      !dialogClaimInfo.typeOfIndimnity && validate.push("typeOfIndimnity");
      dialogClaimInfo.insuranceType == 1 &&
        !dialogClaimInfo.typeOfRisk &&
        validate.push("typeOfRisk");
      dialogClaimInfo.insuranceType == 2 &&
        [4, 5, 6].includes(dialogClaimInfo.typeOfIndimnity) &&
        !dialogClaimInfo.typeOfTreatment &&
        validate.push("typeOfRisk");

      if (validate.length > 0) {
        validate.forEach((i) => {
          errors[i] = true;
        });
        return {
          errors,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Vui lòng nhập đầy đủ thông tin",
          },
        };
      }

      dispatch("onLoading");

      const params = {
        id: detail.id,
        extraInfo: {
          ...detail.extraInfo,
          typeOfInsurance: dialogClaimInfo?.insuranceType,
          typeOfIndimnity: dialogClaimInfo?.typeOfIndimnity,
          typeOfRisk:
            dialogClaimInfo?.insuranceType == 1
              ? dialogClaimInfo?.typeOfRisk
              : null,
          typeOfTreatment:
            dialogClaimInfo?.insuranceType == 2
              ? dialogClaimInfo?.typeOfTreatment
              : null,
        },
      };

      const dataApi = await updateDetail(userTypeFromProfile, params);
      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }
      detail.extraInfo = { ...params.extraInfo };
      dialogClaimInfo.open = false;

      const isHasRelationship = !!detail?.elements?.find(
        (i) => i.type_id === 517
      );
      const listTypeIdLicese = isHasRelationship
        ? [
            ...getListLicenseById({
              typeOfInsurance: detail?.extraInfo?.typeOfInsurance,
              typeOfIndimnity: detail?.extraInfo?.typeOfIndimnity,
              typeOfRisk:
                detail?.extraInfo?.typeOfInsurance == 1
                  ? detail?.extraInfo?.typeOfRisk
                  : detail?.extraInfo?.typeOfTreatment,
            }),
            {
              title: "Chứng từ chứng minh quan hệ",
              typeId: 517,
              typeCode: "relationship",
            },
            {
              title: "Link hóa đơn điện tử",
              typeId: 516,
              typeCode: "e-invoice",
            },
          ]
        : [
            ...getListLicenseById({
              typeOfInsurance: detail?.extraInfo?.typeOfInsurance,
              typeOfIndimnity: detail?.extraInfo?.typeOfIndimnity,
              typeOfRisk:
                detail?.extraInfo?.typeOfInsurance == 1
                  ? detail?.extraInfo?.typeOfRisk
                  : detail?.extraInfo?.typeOfTreatment,
            }),
            {
              title: "Link hóa đơn điện tử",
              typeId: 516,
              typeCode: "e-invoice",
            },
          ];

      const itemEleme = (detail?.elements || []).find((i) => i.type_id === 500);
      const listLicese = itemEleme?.extra_info?.documents || [];
      const imgLicese = {
        ...(_.get(itemEleme, "extra_info.documents[0]") || {}),
        indexImg: 0,
        createdDate: itemEleme.created_date,
        nameLicense: "Hình chụp CMND/ CCCD/ Hộ chiếu mặt trước và sau",
        typeId: 500,
      };
      const typeIdLiceseButton = 500;

      return {
        loading: false,
        detail,
        dialogClaimInfo,
        listTypeIdLicese,
        listLicese,
        imgLicese,
        snackbar: {
          ...snackbar,
          open: true,
          type: "success",
          message: "Cập nhật yêu cầu bồi thường thành công.",
        },
      };
    },

    onClickLicense: () => {
      const { typeId, nameLicense, typeCode } = action;
      const { detail } = state;
      const res = {};

      const itemElemes = (detail?.elements || []).find(
        (i) => i.type_id === typeId
      );

      res.listLicese = itemElemes
        ? itemElemes?.extra_info?.documents || []
        : [];
      res.imgLicese =
        res.listLicese.length > 0
          ? {
              ...((_.get(itemElemes, "extra_info.documents") || []).find(
                (i) => !!i?.path
              ) || {}),
              indexImg: 0,
              nameLicense,
              typeId,
              typeCode,
              created_date: _.get(itemElemes, "created_date") || null,
            }
          : {
              indexImg: 0,
              nameLicense,
              typeId,
              typeCode,
              created_date: _.get(itemElemes, "created_date") || null,
            };

      res.typeIdLiceseButton = typeId;

      return { ...res };
    },

    //* open popover move img
    openPopoverMoveImg: () => {
      const { e, name } = action;
      return { popverMoveImg: { open: true, ref: e.currentTarget, name } };
    },

    //* close popover move img
    onClosePopoverMoveImg: () => {
      return { popverMoveImg: { ...state.popverMoveImg, open: false } };
    },

    onChangePictureBox: () => {
      const { typeAction } = action;
      const { pictureBox } = state;
      switch (typeAction) {
        case "rotated-left":
          return {
            pictureBox: {
              ...pictureBox,
              rotate: (pictureBox.rotate || 0) - 90,
            },
          };
        case "rotated-right":
          return {
            pictureBox: {
              ...pictureBox,
              rotate: (pictureBox.rotate || 0) + 90,
            },
          };
        case "zoom-in":
          return {
            pictureBox: {
              ...pictureBox,
              scale: (pictureBox.scale || 1) - 0.25,
            },
          };
        case "zoom-out":
          return {
            pictureBox: {
              ...pictureBox,
              scale: (pictureBox.scale || 1) + 0.25,
            },
          };
      }
    },

    //*
    moveImgTo: async () => {
      const { item } = action;
      const { detail, imgLicese, listLicese, snackbar, popverMoveImg } = state;
      const oldListLicese = [...listLicese];

      const elementMoveOut =
        detail?.elements.find((i) => i.type_id == imgLicese?.typeId) || {};
      const elementMoveIn = detail?.elements.find(
        (i) => i.type_id == item?.typeId
      ) || {
        extra_info: { documents: [] },
        typeId: item?.typeId,
        typeCode: item?.typeCode,
      };

      const urlDocumentsPdf = [];
      _.isString(
        _.get(elementMoveOut, "extra_info.documents_pdf.path") || null
      ) &&
        urlDocumentsPdf.push(
          _.get(elementMoveOut, "extra_info.documents_pdf.path")
        );
      _.isString(_.get(elementMoveIn, "extra_info.documents_pdf.path")) &&
        urlDocumentsPdf.push(
          _.get(elementMoveIn, "extra_info.documents_pdf.path")
        );

      const itemMove = listLicese[imgLicese.indexImg];
      const indexItemExtraInforMoveOut =
        elementMoveOut?.extra_info?.documents?.findIndex(
          (i) => i.path === itemMove.path
        );

      elementMoveOut?.extra_info?.documents?.splice(
        indexItemExtraInforMoveOut,
        1
      );

      if (
        item?.typeId == 523 &&
        (elementMoveIn?.extra_info?.documents || []).length == 0
      ) {
        elementMoveIn.typeId = 523;
        elementMoveIn.typeCode = "other";
        elementMoveIn.extra_info = { documents: [] };
      }

      elementMoveIn?.extra_info?.documents?.push(itemMove);

      const elementsParams = [
        {
          id: elementMoveOut?.id || null,
          typeId: elementMoveIn.typeId || elementMoveIn.type_id,
          typeCode: elementMoveIn.typeCode || elementMoveIn.type_code,
          claimId: elementMoveOut?.claim_id,
          extraInfo: {
            documents: elementMoveOut?.extra_info?.documents,
            documents_pdf: null,
            ocr: null,
          },
        },
        {
          id: elementMoveIn?.id || null,
          claimId: elementMoveIn?.claim_id || elementMoveOut?.claim_id || null,
          typeId: elementMoveIn.typeId || elementMoveIn.type_id,
          typeCode: elementMoveIn.typeCode || elementMoveIn.type_code,
          extraInfo: {
            documents: elementMoveIn?.extra_info?.documents,
            documents_pdf: null,
            ocr: null,
          },
        },
      ];

      dispatch("onLoading");

      const dataApi = await addNote(systemType, {
        claimId: elementMoveOut.claim_id,
        elements: elementsParams,
      });

      if (dataApi instanceof Error) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
          loading: false,
        };
      }
      if (urlDocumentsPdf.length > 0) {
        const api = urlDocumentsPdf.map((i) => {
          return deleteS3Document({
            action_code: "document_delete",
            extra_info: { url: i },
          });
        });

        Promise.all(api);
      }
      const detailClaimApi = await getDetailClaim(systemType, id);

      if (detailClaimApi?.data?.statusCode === 200) {
      }
      oldListLicese.splice(+imgLicese.indexImg, 1);
      const temp = _.get(detailClaimApi, "data.result") || { ...detail };

      return {
        detail: temp,
        listLicese: [...oldListLicese],
        imgLicese: { ...imgLicese, ...(listLicese[0] || {}) },
        popverMoveImg: { ...popverMoveImg, open: false },
        snackbar: {
          ...snackbar,
          open: true,
          type: "success",
          message: "Di chuyển ảnh thành công",
        },
        loading: false,
      };
    },

    //*change index img
    changeIndexImg: async () => {
      const { newArray } = action;
      const { detail, imgLicese, snackbar } = state;

      if (level_id != 1) {
        return;
      }

      const indexItemElementChang = detail.elements.findIndex(
        (i) => i.type_id == imgLicese.typeId
      );

      const itemExtraInfoChange =
        detail.elements[indexItemElementChang].extra_info;
      const urlDocumentsPdf =
        _.get(itemExtraInfoChange, "documents_pdf.path") || null;
      if (urlDocumentsPdf) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: { url: urlDocumentsPdf },
        });
      }

      delete itemExtraInfoChange.documents_pdf;
      delete itemExtraInfoChange.ocr;
      const newDocuments = newArray
        .map((path) => {
          const item = itemExtraInfoChange.documents.find(
            (i) => i?.path == path
          );
          return item;
        })
        .filter((i) => !_.isEmpty(i));
      itemExtraInfoChange.documents = newDocuments;

      _.set(
        detail,
        `elements[${indexItemElementChang}].extra_info`,
        itemExtraInfoChange
      );

      const param = {
        claimId: _.get(detail.elements, `[${indexItemElementChang}].claim_id`),
        elements: [
          {
            id: _.get(detail.elements, `[${indexItemElementChang}].id`) || null,
            typeId:
              _.get(detail.elements, `[${indexItemElementChang}].type_id`) ||
              null,
            typeCode:
              _.get(detail.elements, `[${indexItemElementChang}].type_code`) ||
              null,
            claimId:
              _.get(detail.elements, `[${indexItemElementChang}].claim_id`) ||
              null,
            extraInfo: {
              documents: newDocuments,
              documents_pdf: null,
              ocr: null,
            },
          },
        ],
      };
      const res = {};

      dispatch("onLoading");
      const dataApi = await addNote(systemType, param);
      if (dataApi?.data?.statusCode == 200) {
        res.snackbar = {
          ...snackbar,
          open: true,
          type: "success",
          message: "Đổi vị trí thành công",
        };
      }

      return { detail, loading: false, ...res };
    },

    downLoadZipImg: async () => {
      const { detail, popverMoveImg } = state;
      dispatch("onLoading");

      const elements = detail.elements || [];
      const listPathAndNameImg = [];
      const listImgFileZip = [];
      elements.forEach((item) => {
        if (
          (Array.isArray(item?.extra_info?.documents)
            ? item?.extra_info?.documents
            : item?.documents || []
          )?.length > 0
        ) {
          (Array.isArray(item?.extra_info?.documents)
            ? item?.extra_info?.documents
            : item?.documents || []
          )?.forEach((el, index) => {
            const temp = listLicenseUnit.find((i) => i.typeId == item.type_id);
            const itemArray = !_.isEmpty(temp)
              ? {
                  name: `${`${temp?.title}`.replace(/\//g, "-")} ${index + 1}`,
                  path: el?.path || "",
                }
              : {};
            !_.isEmpty(itemArray) && listPathAndNameImg.push(itemArray);
          });
        }
      });

      if (listPathAndNameImg.length > 0) {
        await Promise.all(
          listPathAndNameImg.map(async (i) => {
            const dataApi = await getFileByUrl(i.path);
            listImgFileZip.push({ name: i.name, file: dataApi });
          })
        );
      }

      const zip = new JSZip();
      const img = zip.folder("Hình ảnh");
      listImgFileZip.forEach((blob, index) => {
        img.file(`${blob.name}.png`, blob.file, { base64: true });
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "thông báo tổn thất và yêu cầu bồi thường.zip");
      });

      return {
        loading: false,
        popverMoveImg: { ...popverMoveImg, open: false },
      };
    },

    downLoadImg: async () => {
      const { imgLicese, popverMoveImg } = state;
      dispatch("onLoading");
      const dataApi = await getFileByUrl(imgLicese.path);
      saveAs(dataApi, `${imgLicese?.nameLicense}`);
      return {
        loading: false,
        popverMoveImg: { ...popverMoveImg, open: false },
      };
    },

    upLoadImgFromBTV: async () => {
      const { e } = action;
      const { snackbar, detail, imgLicese, listLicese } = state;

      if (level_id != 1) {
        return;
      }

      const files = e.target.files || [];
      dispatch("onLoading");

      const element_documents = [];
      const file_name = [];
      const params = [];
      // const
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const name_file = file.name || "";
        const size_file = file.size || "";
        const data = new FormData();
        data.append("document", file);
        params.push(data);
        file_name.push({
          size: size_file,
          original_name: name_file,
        });
      }

      const dataApi = await Promise.all(params.map((data) => uploadImg(data)));

      dataApi.forEach((valueApi, index) => {
        if (valueApi?.data?.statusCode === 200) {
          //  _.get(dataApi, "data.result[0].path"), original_name
          const itemFileName = file_name[index] || {};
          element_documents.push({
            path: _.get(valueApi, "data.result[0].path") || "",
            size: itemFileName.size || null,
            original_name: itemFileName.original_name || null,
          });
        }
      });
      const elementCurent =
        (detail.elements || []).find(
          (i) => i.type_code == imgLicese?.typeCode
        ) || {};

      const param = {
        claimId: detail.id,
        elements: [
          {
            id: elementCurent?.id || null,
            typeId: imgLicese?.typeId || null,
            typeCode: imgLicese?.typeCode || null,
            claimId: detail.id,
            extraInfo: {
              documents: [
                ...element_documents,
                ...(_.get(elementCurent, "extra_info.documents") || []),
              ],
              documents_pdf: null,
              ocr: null,
            },
          },
        ],
      };
      const res = {};

      if (element_documents.length > 0) {
        const dataApi = await addNote(systemType, param);
        if (dataApi?.data?.statusCode == 200) {
          res.imgLicese = {
            ...imgLicese,
            ..._.get(element_documents, "[0]"),
            indexImg: 0,
          };
          res.listLicese = [...element_documents, ...listLicese];
          res.snackbar = {
            ...snackbar,
            open: true,
            type: "success",
            message: "Upload ảnh thành công",
          };
          res.detail = { ..._.get(dataApi, "data.result") };
          if (
            !_.isEmpty(elementCurent) &&
            Boolean(_.get(elementCurent, "extra_info.documents_pdf.path"))
          ) {
            deleteS3Document({
              action_code: "document_delete",
              extra_info: {
                url: _.get(elementCurent, "extra_info.documents_pdf.path"),
              },
            });
          }
        }
      }

      return { loading: false, ...res };
    },

    //* change image next or pre from file  License
    onChangInmg: () => {
      const { index } = action;
      const { listLicese, imgLicese } = state;
      return {
        imgLicese: {
          ...imgLicese,
          path: listLicese[index].path,
          size: listLicese[index]?.size || 0,
          indexImg: index,
          original_name: listLicese[index]?.original_name || null,
        },
      };
    },

    //* change data comment from file  License
    onChangeLicenseAdd: () => {
      const { value, name } = action;
      return { [name]: value };
    },

    //* fucn when onClick button in comment from file  License
    addCommentLicense: () => {
      const {
        additionalLicenseSelect,
        additionalLicenseText,
        listCommentLinceseAdd,
        detail,
        listTypeIdLicese,
        isEditComment,
        snackbar,
      } = state;

      if (!additionalLicenseSelect || _.isEmpty(additionalLicenseText)) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Vui lòng chọn loại, và nhập nội dung đầy đủ",
          },
        };
      }

      const res = {};

      let itemCmtChange = listCommentLinceseAdd?.find((i) => i.isNewCmt) || {};
      const indexCmtChange = listCommentLinceseAdd?.findIndex(
        (i) => i.isNewCmt
      );

      const listLicense = [
        ...listLicenseUnit.map((i) => {
          return {
            title: i.title,
            typeId: i.typeId,
            typeCode: i.typeCode,
          };
        }),
        {
          title: "Chứng từ chứng minh quan hệ",
          typeId: 517,
          typeCode: "relationship",
        },
      ];

      const itemListCommentLinceseAdd = listLicense.find(
        (i) => i.typeId === additionalLicenseSelect
      );

      const newItem = {
        title: itemListCommentLinceseAdd.title,
        typeId: itemListCommentLinceseAdd.typeId,
        typeCode: itemListCommentLinceseAdd.typeCode,
        text: additionalLicenseText,
      };

      if (indexCmtChange > -1) {
        if (isEditComment.status) {
          itemCmtChange.comments[isEditComment.index] = newItem;
        } else {
          itemCmtChange.comments.push(newItem);
        }
        listCommentLinceseAdd[indexCmtChange] = itemCmtChange;
      } else {
        itemCmtChange = {
          comments: [newItem],
          isNewCmt: true,
          created_date: moment().format(),
        };
        listCommentLinceseAdd.push(itemCmtChange);
      }

      return {
        listCommentLinceseAdd,
        additionalLicenseSelect: null,
        additionalLicenseText: null,
        isEditComment: { ...isEditComment, status: false },
      };
    },

    //* fucn to edit comment from file  License
    editComment: () => {
      const { index, item } = action;
      return {
        additionalLicenseSelect: item.typeId,
        additionalLicenseText: item.text,
        isEditComment: { status: true, index },
      };
    },

    //* fucn to delete comment from file  License
    deleteComment: () => {
      const { index } = action;
      const { listCommentLinceseAdd } = state;

      const itemCmtChange = listCommentLinceseAdd?.find((i) => i.isNewCmt);
      const indexCmtChange = listCommentLinceseAdd?.findIndex(
        (i) => i.isNewCmt
      );

      itemCmtChange.comments.splice(index, 1);

      listCommentLinceseAdd[indexCmtChange] = itemCmtChange;

      return { listCommentLinceseAdd };
    },

    //* fucn to submit add comment from file  License
    submitAdditionalRequest: async () => {
      const { detail, listCommentLinceseAdd, snackbar } = state;

      dispatch("closeDialogNoti");
      const claimId = _.get(detail, "elements[0].claim_id") || "";
      const itemCmtChange =
        listCommentLinceseAdd?.find((i) => i.isNewCmt) || {};
      const extraInfo = (itemCmtChange?.comments || [])?.map((i) => {
        return { typeId: i.typeId, typeCode: i.typeCode, text: i.text };
      });

      if (extraInfo.length == 0) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "warning",
            message: "Vui lòng thêm chứng từ cần bổ sung",
          },
        };
      }

      dispatch("onLoading");

      const params = {
        claimId,
        elements: [
          {
            typeId: 520,
            typeCode: "action_element",
            extraInfo: { elements_amend: extraInfo },
          },
        ],
      };

      const dataApi = await additionalRequest(systemType, params);

      if (dataApi instanceof Error) {
        dispatch({
          type: "openDialogNoti",
          typeDialog: "error",
          textContent: [
            "Yêu cầu bổ sung chứng từ của bạn đã ",
            "gửi đi không thành công",
          ],
        });
      }

      if (dataApi?.data?.statusCode === 200) {
        dispatch({
          type: "openDialogNoti",
          typeDialog: "success",
          textContent: [
            "Yêu cầu bổ sung chứng từ của bạn đã ",
            "được gửi đi thành công",
          ],
        });
        const indexCmtChange = listCommentLinceseAdd?.findIndex(
          (i) => i.isNewCmt
        );
        listCommentLinceseAdd[indexCmtChange] = {
          ...listCommentLinceseAdd[indexCmtChange],
          isNewCmt: false,
        };
        // Thêm chỗ này??

        detail.statusId = 4;

        const elements_amend_curren = detail?.elements
          .filter((i) => (i?.extra_info?.elements_amend || []).length > 0)
          .map((i) => i.id);
        const elements_amend_api = dataApi?.data?.result?.elements?.find(
          (i) =>
            (i?.extra_info?.elements_amend || []).length > 0 &&
            !elements_amend_curren.includes(i.id)
        );

        elements_amend_api && detail.elements.push(elements_amend_api);
      }

      return { loading: false, listCommentLinceseAdd, detail };
    },

    transData: () => {
      const { data } = action;
      const { tabsDetail } = state;

      if (data.tabsDetail > 0 && data.tabsDetail !== tabsDetail.value) {
        const value = data.tabsDetail;
        delete data.tabsDetail;
        dispatch({ type: "onChangeTabs", value });
      }
      return { ...data };
    },

    initialClaimLicense: async () => {
      dispatch("onLoading");
      const res = {};
      const dataApi = await getDetailClaim(systemType, id);

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...state.snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại",
          },
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        const temp = _.get(dataApi, "data.result") || {};
        const examinationFees = temp?.extraInfo?.examinationFees || [];
        const bills = (temp?.extraInfo?.bills || []).map((i) => {
          if (i?.approveStatus !== 0 && !i.approveStatus) {
            return { ...i, approveFee: i.fee, approveStatus: 1 };
          }
          return { ...i };
        });
        const medicines = temp?.extraInfo?.medicines || [];
        const other = temp?.extraInfo?.others || [];
        let otherTables = [];

        switch (temp?.extraInfo?.typeOfIndimnity) {
          case 3:
            otherTables = (temp?.extraInfo?.injuries || []).map((i) => ({
              ...i,
              otherText: i.injuryType || "",
            }));
            break;
          case 5:
            otherTables = (
              temp?.extraInfo?.canceOrFatalDiseaseOrStroke || []
            ).map((i) => ({
              ...i,
              otherText: i.diseaseType || "",
            }));
            break;
          case 6:
            otherTables = (temp?.extraInfo?.deaths || []).map((i) => ({
              ...i,
              otherText: i.reasonOfDeath || "",
            }));
            break;

          default:
            break;
        }
        const listCodeBenefitExaminationFees = [];
        const listCodeBenefitBillFees = [];
        const listCodeBenefitmedicines = [];
        const listCodeBenefitOtherTables = [];
        let sumExaminationFeesApprove = 0;
        let sumBillFeesApprove = 0;
        let sumMedicinesApprove = 0;
        let sumOtherBenefitApprove = 0;
        let sumOtherTablesApprove = 0;

        examinationFees.forEach((i) => {
          sumExaminationFeesApprove += +(i?.approveFee || 0);
          !listCodeBenefitExaminationFees.includes(i.codeBenefit) &&
            listCodeBenefitExaminationFees.push(i.codeBenefit);
        });

        bills.forEach((i) => {
          sumBillFeesApprove += +(i?.approveFee || 0);
          !listCodeBenefitBillFees.includes(i.codeBenefit) &&
            listCodeBenefitBillFees.push(i.codeBenefit);
        });

        medicines.forEach((i) => {
          sumMedicinesApprove += +(i?.approveFee || 0);
          !listCodeBenefitmedicines.includes(i.codeBenefit) &&
            listCodeBenefitmedicines.push(i.codeBenefit);
        });

        other.forEach((i) => {
          sumOtherBenefitApprove += +(i?.approveFee || 0);
        });

        otherTables.forEach((i) => {
          sumOtherTablesApprove += +i?.approveFee || 0;
          !listCodeBenefitOtherTables.includes(i.codeBenefit) &&
            listCodeBenefitOtherTables.push(i.codeBenefit);
        });

        res.detail = temp;
        const itenNoteuserL1 = temp.elements?.find((i) => i.type_id == 521);
        res.insuredPersonInformation = {
          ...temp.insuredInfo,
          ...temp.extraInfo,
        };

        res.rowsDiagnosis = temp?.extraInfo?.doctorDiagnoses || [];

        res.rowsExaminationApprove = listCodeBenefitExaminationFees.reduce(
          (array, item) => {
            const listItem = examinationFees
              .filter((i) => i.codeBenefit === item)
              .map((i) => {
                if (i?.approveStatus !== 0 && !i.approveStatus) {
                  return { ...i, approveFee: i.fee, approveStatus: 1 };
                }
                return { ...i };
              });

            let totalFeeCodeBenefit = 0;
            let totalPaidFee = 0;
            let totalFeeCodeBenefitApprove = 0;

            listItem.forEach((i) => {
              totalFeeCodeBenefit += +(i?.fee || 0);
              totalPaidFee += +(i?.paidFee || 0);
              totalFeeCodeBenefitApprove += +(i?.approveFee || 0);
            });

            array = [
              ...array,
              {
                codeBenefit: item,
                titleBenefit: listItem[0]?.titleBenefit,
                totalFeeCodeBenefit,
                totalPaidFee,
                totalFeeCodeBenefitApprove,
                isParent: true,
              },
              ...listItem,
            ];
            return array;
          },
          []
        );

        res.rowsBillsApprove = listCodeBenefitBillFees.reduce((array, item) => {
          const listItem = bills.filter((i) => i.codeBenefit === item);
          let totalFeeCodeBenefit = 0;
          let totalPaidFee = 0;
          let totalFeeCodeBenefitApprove = 0;

          listItem.forEach((i) => {
            totalFeeCodeBenefit += +(i?.fee || 0);
            totalPaidFee += +(i?.paidFee || 0);
            totalFeeCodeBenefitApprove += +(i?.approveFee || 0);
          });

          array = [
            ...array,
            {
              codeBenefit: item,
              titleBenefit: listItem[0]?.titleBenefit,
              totalFeeCodeBenefit,
              totalPaidFee,
              totalFeeCodeBenefitApprove,
              isParent: true,
            },
            ...listItem,
          ];
          return array;
        }, []);

        res.rowsMedicinesApprove = listCodeBenefitmedicines.reduce(
          (array, item) => {
            const listItem = medicines
              .filter((i) => i.codeBenefit === item)
              .map((i) => {
                if (i?.approveStatus !== 0 && !i.approveStatus) {
                  return {
                    ...i,
                    approveFee: i?.approveFee || i.fee,
                    approveStatus: [0, 1].includes(+i?.approveStatus)
                      ? i?.approveStatus
                      : 1,
                  };
                }
                return { ...i };
              });

            let totalFeeCodeBenefit = 0;
            let totalPaidFee = 0;
            let totalFeeCodeBenefitApprove = 0;

            listItem.forEach((i) => {
              totalFeeCodeBenefit += +(i?.fee || 0);
              totalPaidFee += +(i?.paidFee || 0);
              totalFeeCodeBenefitApprove += +(i?.approveFee || 0);
            });

            array = [
              ...array,
              {
                codeBenefit: item,
                titleBenefit: listItem[0]?.titleBenefit,
                totalFeeCodeBenefit,
                totalPaidFee,
                totalFeeCodeBenefitApprove,
                isParent: true,
              },
              ...listItem,
            ];
            return array;
          },
          []
        );

        res.rowsOtherTablesApprove = listCodeBenefitOtherTables.reduce(
          (array, item) => {
            const listItem = otherTables
              .filter((i) => i.codeBenefit === item)
              .map((i) => {
                if (i?.approveStatus !== 0 && !i.approveStatus) {
                  return {
                    ...i,
                    approveFee: i?.approveFee || i.fee,
                    approveStatus: [0, 1].includes(+i?.approveStatus)
                      ? i?.approveStatus
                      : 1,
                  };
                }
                return { ...i };
              });

            let totalFee = 0;
            let totalFeeCodeBenefitApprove = 0;
            listItem.forEach((i) => {
              totalFee += +(i?.fee || 0);
              totalFeeCodeBenefitApprove += +(i?.approveFee || 0);
            });

            array = [
              ...array,
              {
                codeBenefit: item,
                titleBenefit: listItem[0]?.titleBenefit,
                totalFee,
                benefitAmount: _.get(listItem, "[0].benefitAmount") || "",
                isParent: true,
                totalFeeCodeBenefitApprove: totalFeeCodeBenefitApprove,
              },
              ...listItem,
            ];
            return array;
          },
          []
        );
        res.rowsOtherBenefits = other;
        res.approveExaminationFeesTotal = sumExaminationFeesApprove;
        res.approveBillFeesTotal = sumBillFeesApprove;
        res.approveMedicineFeesTotal = sumMedicinesApprove;
        res.approveOthersTotal = sumOtherBenefitApprove;
        res.approveOtherTablesTotal = sumOtherTablesApprove;
        res.finalFeesTotalApprove = Math.ceil(
          +sumExaminationFeesApprove +
            +sumBillFeesApprove +
            +sumMedicinesApprove +
            +sumOtherBenefitApprove +
            +sumOtherTablesApprove
        );

        res.listNote = (temp?.elements || []).filter(
          (i) =>
            (i.type_id == 522 && i.type_code == "note") ||
            (i.type_id == 520 && i.status_id == 8)
        );
        res.noteUseLV1 = {
          note: _.get(itenNoteuserL1, "text") || "",
          approvedPercent:
            temp?.extraInfo?.approvedPercent == 0
              ? temp?.extraInfo?.approvedPercent
              : temp?.extraInfo?.approvedPercent || 100,
          amount:
            ((temp?.extraInfo?.approvedPercent == 0
              ? temp?.extraInfo?.approvedPercent
              : temp?.extraInfo?.approvedPercent || 100) *
              res.finalFeesTotalApprove) /
            100,
        };
      }

      return { ...res, loading: false };
    },

    diagnosisChangTableApprove: () => {
      const {
        data: { index, name, value = null },
        ele,
      } = action;
      const { rowsDiagnosis } = state;
      rowsDiagnosis[index] = { ...rowsDiagnosis[index], [name]: value };
      if (name === "note") {
        rowsDiagnosis[index] = {
          ...rowsDiagnosis[index],
          [name]: ele?.target?.value,
        };
      }

      return { rowsDiagnosis };
    },

    examinationChangTableApprove: () => {
      const { name, index, value, item } = action;
      const {
        rowsExaminationApprove,
        snackbar,
        approveMedicineFeesTotal,
        approveBillFeesTotal,
        approveOthersTotal,
      } = state;

      const indexParent = rowsExaminationApprove.findIndex(
        (i) => i.codeBenefit == item.codeBenefit && !!i.isParent
      );

      if (
        name === "approveFee" &&
        (+item?.fee < +`${value || 0}`.replace(/\./g, "") ||
          item.approveStatus === 0)
      ) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message:
              item.approveStatus === 0
                ? "Mục này đã bị từ chối bồi thường"
                : "Sồ tiền phê duyệt không được vượt quá số tiền yêu cầu",
          },
        };
      }

      rowsExaminationApprove[index] = {
        ...rowsExaminationApprove[index],
        [name]: value,
      };

      if (name == "note") {
        return { rowsExaminationApprove };
      }

      if (name === "approveStatus") {
        rowsExaminationApprove[index] = {
          ...rowsExaminationApprove[index],
          approveFee: !value ? 0 : rowsExaminationApprove[index]?.fee,
        };
      }

      let totalFee = 0;
      let totalPaidFee = 0;
      rowsExaminationApprove
        .filter((i) => i.codeBenefit === item?.codeBenefit && !i.isParent)
        ?.forEach((a) => {
          totalFee += +(a?.approveFee || 0);
          totalPaidFee += +(a?.paidFee || 0);
        });

      rowsExaminationApprove[indexParent] = {
        ...rowsExaminationApprove[indexParent],
        totalFeeCodeBenefitApprove: totalFee,
        totalPaidFee,
      };

      let approveExaminationFeesTotal = 0;
      rowsExaminationApprove
        .filter((i) => !i.isParent)
        ?.forEach((a) => {
          approveExaminationFeesTotal += +(a?.approveFee || 0);
        });

      const finalFeesTotalApprove = Math.ceil(
        approveMedicineFeesTotal +
          approveOthersTotal +
          approveBillFeesTotal +
          approveExaminationFeesTotal
      );
      dispatch({ type: "changeMoney", finalFeesTotalApprove });

      return {
        rowsExaminationApprove,
        approveExaminationFeesTotal,
        finalFeesTotalApprove,
      };
    },

    billChangTableApprove: () => {
      const { name, index, value, item } = action;
      const {
        rowsBillsApprove,
        snackbar,
        approveMedicineFeesTotal,
        approveExaminationFeesTotal,
        approveOthersTotal,
      } = state;

      const indexParent = rowsBillsApprove.findIndex(
        (i) => i.codeBenefit == item.codeBenefit && !!i.isParent
      );

      if (
        name === "approveFee" &&
        (+item?.fee < +`${value || 0}`.replace(/\./g, "") ||
          item.approveStatus === 0)
      ) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message:
              item.approveStatus === 0
                ? "Mục này đã bị từ chối bồi thường"
                : "Sồ tiền phê duyệt không được vượt quá số tiền yêu cầu",
          },
        };
      }

      rowsBillsApprove[index] = {
        ...rowsBillsApprove[index],
        [name]: value,
      };

      if (name == "note") {
        return { rowsBillsApprove };
      }

      if (name === "approveStatus") {
        rowsBillsApprove[index] = {
          ...rowsBillsApprove[index],
          approveFee: !value ? 0 : rowsBillsApprove[index]?.fee,
        };
      }

      let totalFee = 0;
      let totalPaidFee = 0;
      rowsBillsApprove
        .filter((i) => i.codeBenefit === item?.codeBenefit && !i.isParent)
        ?.forEach((a) => {
          totalFee += +(a?.approveFee || 0);
          totalPaidFee += +(a?.paidFee || 0);
        });

      rowsBillsApprove[indexParent] = {
        ...rowsBillsApprove[indexParent],
        totalFeeCodeBenefitApprove: totalFee,
        totalPaidFee,
      };

      let approveBillFeesTotal = 0;
      rowsBillsApprove
        .filter((i) => !i.isParent)
        ?.forEach((a) => {
          approveBillFeesTotal += +(a?.approveFee || 0);
        });

      const finalFeesTotalApprove = Math.ceil(
        approveMedicineFeesTotal +
          approveOthersTotal +
          approveBillFeesTotal +
          approveExaminationFeesTotal
      );
      dispatch({ type: "changeMoney", finalFeesTotalApprove });
      return {
        rowsBillsApprove,
        approveBillFeesTotal,
        finalFeesTotalApprove,
      };
    },

    medicineChangTableApprove: () => {
      const { name, index, value, item } = action;
      const {
        rowsMedicinesApprove,
        snackbar,
        approveExaminationFeesTotal,
        approveBillFeesTotal,
        approveOthersTotal,
      } = state;

      const indexParent = rowsMedicinesApprove.findIndex(
        (i) => i.codeBenefit == item.codeBenefit && !!i.isParent
      );

      if (
        name === "approveFee" &&
        (+item?.fee < +`${value || 0}`.replace(/\./g, "") ||
          item.approveStatus === 0)
      ) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message:
              item.approveStatus === 0
                ? "Mục này đã bị từ chối bồi thường"
                : "Sồ tiền phê duyệt không được vượt quá số tiền yêu cầu",
          },
        };
      }

      rowsMedicinesApprove[index] = {
        ...rowsMedicinesApprove[index],
        [name]: value,
      };

      if (name == "note") {
        return { rowsMedicinesApprove };
      }

      if (name === "approveStatus") {
        rowsMedicinesApprove[index] = {
          ...rowsMedicinesApprove[index],
          approveFee: !value ? 0 : rowsMedicinesApprove[index]?.fee,
        };
      }

      let totalFee = 0;
      let totalPaidFee = 0;
      rowsMedicinesApprove
        .filter((i) => i.codeBenefit === item?.codeBenefit && !i.isParent)
        ?.forEach((a) => {
          totalFee += +(a?.approveFee || 0);
          totalPaidFee += +(a?.paidFee || 0);
        });

      rowsMedicinesApprove[indexParent] = {
        ...rowsMedicinesApprove[indexParent],
        totalFeeCodeBenefitApprove: totalFee,
        totalPaidFee,
      };

      let approveMedicineFeesTotal = 0;
      rowsMedicinesApprove
        .filter((i) => !i.isParent)
        ?.forEach((a) => {
          approveMedicineFeesTotal += +(a?.approveFee || 0);
        });

      const finalFeesTotalApprove = Math.ceil(
        approveExaminationFeesTotal +
          approveOthersTotal +
          approveBillFeesTotal +
          approveMedicineFeesTotal
      );

      dispatch({ type: "changeMoney", finalFeesTotalApprove });
      return {
        rowsMedicinesApprove,
        approveMedicineFeesTotal,
        finalFeesTotalApprove,
      };
    },

    othersBenefitChangTableApprove: () => {
      const {
        data: { name, index, item },
        ele,
      } = action;
      const {
        rowsOtherBenefits,
        snackbar,
        approveExaminationFeesTotal,
        approveBillFeesTotal,
        approveMedicineFeesTotal,
      } = state;

      const value = +`${ele?.target?.value || 0}`.replace(/\./g, "");
      if (
        name === "approveFee" &&
        (+item?.fee < +`${value || 0}`.replace(/\./g, "") ||
          item.approveStatus === 0)
      ) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message:
              item.approveStatus === 0
                ? "Mục này đã bị từ chối bồi thường"
                : "Sồ tiền phê duyệt không được vượt quá số tiền yêu cầu",
          },
        };
      }

      rowsOtherBenefits[index] = {
        ...rowsOtherBenefits[index],
        [name]: value,
      };

      let totalFee = 0;
      rowsOtherBenefits.forEach((a) => {
        totalFee += +(a?.approveFee || 0);
      });

      const approveOthersTotal = totalFee;
      const finalFeesTotalApprove = Math.ceil(
        approveExaminationFeesTotal +
          approveOthersTotal +
          approveBillFeesTotal +
          approveMedicineFeesTotal
      );

      dispatch({ type: "changeMoney", finalFeesTotalApprove });
      return {
        rowsOtherBenefits,
        approveOthersTotal,
        finalFeesTotalApprove,
      };
    },

    otherTablesChangTableApprove: () => {
      const { name, index, value, item } = action;
      const { rowsOtherTablesApprove, snackbar } = state;

      const indexParent = rowsOtherTablesApprove.findIndex(
        (i) => i.codeBenefit == item.codeBenefit && !!i.isParent
      );

      if (
        name === "approveFee" &&
        (+item?.fee < +`${value || 0}`.replace(/\./g, "") ||
          item.approveStatus === 0)
      ) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message:
              item.approveStatus === 0
                ? "Mục này đã bị từ chối bồi thường"
                : "Sồ tiền phê duyệt không được vượt quá số tiền yêu cầu",
          },
        };
      }

      rowsOtherTablesApprove[index] = {
        ...rowsOtherTablesApprove[index],
        [name]: value,
      };

      if (name == "note") {
        return { rowsOtherTablesApprove };
      }

      if (name === "approveStatus") {
        rowsOtherTablesApprove[index] = {
          ...rowsOtherTablesApprove[index],
          approveFee: !value ? 0 : rowsOtherTablesApprove[index]?.fee,
        };
      }

      let totalFee = 0;
      rowsOtherTablesApprove
        .filter((i) => i.codeBenefit === item?.codeBenefit && !i.isParent)
        ?.forEach((a) => {
          totalFee += +a?.approveFee || 0;
        });

      rowsOtherTablesApprove[indexParent] = {
        ...rowsOtherTablesApprove[indexParent],
        totalFeeCodeBenefitApprove: totalFee,
      };

      let approveOtherTablesTotal = 0;
      rowsOtherTablesApprove
        .filter((i) => !i.isParent)
        ?.forEach((a) => {
          approveOtherTablesTotal += +a?.approveFee || 0;
        });
      const finalFeesTotalApprove = Math.ceil(approveOtherTablesTotal);
      dispatch({ type: "changeMoney", finalFeesTotalApprove });
      return {
        rowsOtherTablesApprove,
        approveOtherTablesTotal,
        finalFeesTotalApprove,
      };
    },

    onChangeNoteLV1: () => {
      const { name, value } = action;
      const { noteUseLV1, snackbar, finalFeesTotalApprove } = state;

      if (
        (name === "approvedPercent" && value > 100) ||
        (name === "amount" && value > finalFeesTotalApprove)
      ) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message:
              name === "percent"
                ? "Tỉ lệ không quá 100%"
                : "Sồ tiền bạn nhập chưa hợp lý",
          },
        };
      }

      noteUseLV1[name] = value;

      if (name === "approvedPercent") {
        noteUseLV1.amount = Math.ceil((finalFeesTotalApprove * +value) / 100);
      }

      if (name === "amount") {
        noteUseLV1.approvedPercent = (
          (100 * value) /
          finalFeesTotalApprove
        ).toFixed(1);
      }

      return { noteUseLV1 };
    },

    //* func to export file of button "Xuất hồ sơ" from file claimLicense
    submitExportLicense: async () => {
      const {
        detail,
        finalFeesTotal,
        rowsDiagnosis,
        rowsExaminationApprove,
        rowsBillsApprove,
        rowsMedicinesApprove,
        rowsOtherBenefits,
        rowsOtherTablesApprove,
        examinationFeesTotal,
        approveExaminationFeesTotal,
        medicineFeesTotal,
        approveMedicineFeesTotal,
        othersTotal,
        approveOthersTotal,
        finalFeesTotalApprove,
        noteUseLV1,
        insuredPersonInformation,
        dialogSendApproval,
        billsTotal,
        approveBillFeesTotal,
        approveOtherTablesTotal,
        insuranceTypes,
      } = state;
      const claimId = _.get(detail, "elements[0].claim_id") || "";

      dispatch("onLoadingClaimLicense");

      const rowsExamination = rowsExaminationApprove.filter((i) => !i.isParent);
      const rowsBills = rowsBillsApprove.filter((i) => !i.isParent);
      const rowsMedicines = rowsMedicinesApprove.filter((i) => !i.isParent);
      const rowsOtherTables = rowsOtherTablesApprove.filter((i) => !i.isParent);

      let temp = {};

      const {
        check_rowsExaminationApprove,
        check_rowsBillsApprove,
        check_rowsMedicinesApprove,
        check_rowsOtherBenefits,
      } = checkBenefitFeedApprove({
        rowsExaminationApprove,
        rowsBillsApprove,
        rowsMedicinesApprove,
        rowsOtherBenefits,
      });

      const check_approve_fee_rowsExaminationApprove =
        check_rowsExaminationApprove.some((i) => i?.errorFeedApprove);
      const check_approve_fee_rowsBillsApprove = check_rowsBillsApprove.some(
        (i) => i?.errorFeedApprove
      );
      const check_approve_fee_rowsMedicinesApprove =
        check_rowsMedicinesApprove.some((i) => i?.errorFeedApprove);
      const check_approve_fee_rowsOtherBenefits = check_rowsOtherBenefits.some(
        (i) => i?.errorFeedApprove
      );

      if (
        check_approve_fee_rowsExaminationApprove ||
        check_approve_fee_rowsBillsApprove ||
        check_approve_fee_rowsMedicinesApprove ||
        check_approve_fee_rowsOtherBenefits
      ) {
        return {
          loadingClaimLicense: false,
          rowsExaminationApprove: [...check_rowsExaminationApprove],
          rowsBillsApprove: [...check_rowsBillsApprove],
          rowsMedicinesApprove: [...check_rowsMedicinesApprove],
          rowsOtherBenefits: [...check_rowsOtherBenefits],
          snackbar: {
            open: true,
            type: "error",
            message:
              "Dường như có một só quyền lợi bồi thường số tiền quá giới hạn hoặc thiếu thông tin, vui lòng kiểm tra lại",
          },
        };
      }

      switch (detail?.extraInfo?.typeOfIndimnity) {
        case 3:
          temp = {
            injuryFeesTotal: detail?.extraInfo?.injuryFeesTotal,
            approveInjuryFeesTotal: approveOtherTablesTotal,
            injuries: rowsOtherTables.map((i) => ({
              ...i,
              injuryType: i.otherText,
              fee: +`${i.fee || "0"}`.replace(/\./g, ""),
            })),
          };
          break;
        case 5:
          temp = {
            canceOrFatalDiseaseOrStrokeFeesTotal:
              detail?.extraInfo?.canceOrFatalDiseaseOrStrokeFeesTotal,
            approveCanceOrFatalDiseaseOrStrokeFeesTotal:
              approveOtherTablesTotal,
            canceOrFatalDiseaseOrStroke: rowsOtherTables.map((i) => ({
              ...i,
              diseaseType: i.otherText,
              fee: +`${i.fee || "0"}`.replace(/\./g, ""),
            })),
          };
          break;
        case 6:
          temp = {
            deathFeesTotal: detail?.extraInfo?.deathFeesTotal,
            approveDeathFeesTotal: approveOtherTablesTotal,
            deaths: rowsOtherTables.map((i) => ({
              ...i,
              reasonOfDeath: i.otherText,
              fee: +`${i.fee || "0"}`.replace(/\./g, ""),
            })),
          };
          break;
        default:
          break;
      }

      const dataTableExtraInfo = [3, 5, 6].includes(
        +detail?.extraInfo?.typeOfIndimnity
      )
        ? {
            doctorDiagnoses: [],
            examinationFeesTotal: 0,
            approveExaminationFeesTotal: 0,
            examinationFees: [],
            billsTotal: 0,
            approveBillFeesTotal: 0,
            bills: [],
            medicineFeesTotal: 0,
            approveMedicineFeesTotal: 0,
            medicines: [],
            othersTotal,
            approveOthersTotal,
            others: rowsOtherBenefits?.map((i) => {
              return {
                ...i,
                numberOfDays: +i?.numberOfDays,
                price: +i?.price,
                fee: +i?.fee,
                approveStatus: 1,
                approveFee: +`${i?.approveFee || ""}`.replace(/\./g, ""),
                note: i?.note,
              };
            }),
            ...temp,
          }
        : {
            doctorDiagnoses: rowsDiagnosis?.map((i) => {
              return {
                diseaseCode: i?.diseaseCode,
                diseaseName: i?.diseaseName,
                approveStatus: i?.approveStatus,
                note: i?.note,
              };
            }),
            examinationFeesTotal,
            approveExaminationFeesTotal,
            examinationFees: rowsExamination?.map((i) => {
              return {
                ...i,
                approveFee: +`${i?.approveFee || ""}`.replace(/\./g, ""),
                paidFee: +`${i?.paidFee || ""}`.replace(/\./g, ""),
              };
            }),
            medicineFeesTotal,
            approveMedicineFeesTotal,
            medicines: rowsMedicines?.map((i) => {
              return {
                ...i,
                amount: +i?.amount,
                price: +i?.price,
                fee: +i?.fee,
                approveFee: +`${i?.approveFee || ""}`.replace(/\./g, ""),
                paidFee: +`${i?.paidFee || ""}`.replace(/\./g, ""),
              };
            }),
            billsTotal,
            approveBillFeesTotal,
            bills: rowsBills?.map((i) => {
              return {
                amount: +i?.amount,
                price: +i?.price,
                fee: +i?.fee,
                approveFee: +`${i?.approveFee || ""}`.replace(/\./g, ""),
                paidFee: +`${i?.paidFee || ""}`.replace(/\./g, ""),
              };
            }),
            othersTotal,
            approveOthersTotal,
            others: rowsOtherBenefits?.map((i) => {
              return {
                ...i,
                numberOfDays: +i?.numberOfDays,
                price: +i?.price,
                fee: +i?.fee,
                approveStatus: 1,
                approveFee: +`${i?.approveFee || ""}`.replace(/\./g, ""),
              };
            }),
          };

      const params = {
        claimId,
        extraInfo: {
          countCheckInput: 1,
          typeOfInsurance: insuredPersonInformation?.typeOfInsurance || null,
          typeOfIndimnity: insuredPersonInformation?.typeOfIndimnity || null,
          typeOfRisk: insuredPersonInformation?.typeOfRisk || null,
          typeOfTreatment: insuredPersonInformation?.typeOfTreatment || null,
          treatmentPlace: insuredPersonInformation?.treatmentPlace || null,
          departmentTreatment:
            insuredPersonInformation?.departmentTreatment || null,
          dateOfHospital: insuredPersonInformation?.dateOfHospital || null,
          dateOutOfHospital:
            insuredPersonInformation?.dateOutOfHospital || null,
          timeInHospital: insuredPersonInformation?.timeInHospital || null,
          doctorName: insuredPersonInformation?.doctorName || null,
          finalFeesTotal: finalFeesTotal || null,
          approveAmount: finalFeesTotalApprove || null,
          approvedPercent: noteUseLV1?.approvedPercent || null,
          amount: noteUseLV1?.amount || null,
          ...dataTableExtraInfo,
        },
        elements: [
          {
            typeId: 521,
            typeCode: "note_pdf",
            text: noteUseLV1?.note,
          },
        ],
      };

      const res = {};
      const dataDetail = {
        ...detail,
        extraInfo: { ...detail.extraInfo, ...params.extraInfo },
      };

      const listTypeId = [
        500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513,
        514, 515, 523, 524, 525, 528, 531, 532, 533, 534, 535, 536, 537,
      ];
      const temps = [];
      const listImg = (detail?.elements || []).reduce((array, item) => {
        if (listTypeId.includes(+item?.type_id)) {
          const get_img_in_item = (item?.extra_info?.documents || [])
            .filter((i) => !!i.path)
            .map((i) => i.path);
          return [...array, ...get_img_in_item];
        }
        return array;
      }, []);
      for (let iFor = 0; iFor < listImg.length; iFor++) {
        const dataApi = await getFileByUrl(listImg[iFor]);

        if (dataApi) {
          const newImg = await resizeFile(dataApi);
          newImg && temps.push(newImg);
        }
      }
      const blobPdf = await new pdf(
        ExportClaimPDF({
          detail: dataDetail,
          noteUseLV1: noteUseLV1,
          approveAmount: noteUseLV1?.amount,
          listImgBase64PDFfile: [...temps],
          insuranceTypes: insuranceTypes,
        })
      ).toBlob();

      const returnError = {
        loadingClaimLicense: false,
        snackbar: {
          open: true,
          type: "error",
          message: "Đã xảy ra lỗi khi xuất hồ sơ. xin vui lòng thử lại",
        },
      };

      if (!blobPdf) {
        return returnError;
      }

      temps?.length > 0 &&
        dispatch({ type: "transData", data: { listImgBase64PDFfile: temps } });

      const myFile = new File([blobPdf], `myFile.pdf`);
      const data = new FormData();
      data.append("document", myFile);

      const cdnPdfApi = await uploadImg(data);

      if (cdnPdfApi instanceof Error) {
        return returnError;
      }

      res.currentFilePdf = {
        path: _.get(cdnPdfApi, "data.result[0].path") || "",
        text: noteUseLV1?.note,
      };

      if (!_.isEmpty(res?.currentFilePdf?.path)) {
        const newParmnas = {
          claimId,
          extraInfo: { ...params.extraInfo },
          elements: [
            {
              claimId,
              typeId: 521,
              typeCode: "note_pdf",
              text: noteUseLV1?.note,
              extraInfo: {
                documents: [
                  {
                    path: res?.currentFilePdf?.path || null,
                  },
                ],
                approve_info: {
                  request_amount: detail?.requestAmount || 0,
                  approve_amount: noteUseLV1?.amount || 0,
                  approve_percent: noteUseLV1?.approvedPercent || 0,
                  note: noteUseLV1.note || "",
                },
              },
            },
          ],
        };

        const fileApiAgain = await fileLicenseClaim(systemType, newParmnas);

        if (fileApiAgain instanceof Error) {
          return returnError;
        }

        res.detail = { ...dataDetail };
        res.dialogSendApproval = { ...dialogSendApproval, open: true };
        res.currentFilePdf = {
          ...res.currentFilePdf,
          id_element: _.get(fileApiAgain, "data.result.elements[0].id"),
          userAssign: _.get(
            fileApiAgain,
            "data.result.elements[0].extra_info.user.userAssign"
          ),
        };
      }

      return {
        rowsExaminationApprove: [...check_rowsExaminationApprove],
        rowsBillsApprove: [...check_rowsBillsApprove],
        rowsMedicinesApprove: [...check_rowsMedicinesApprove],
        rowsOtherBenefits: [...check_rowsOtherBenefits],
        loadingClaimLicense: false,
        ...res,
      };
    },

    //* func to send request appropve file of button "Gửi yêu cầu" from file claimLicense
    sendRequestApprpve: async () => {
      const {
        detail,
        dialogSendApproval,
        currentFilePdf,
        rowsHistoryTable1,
        dialogNote,
      } = state;

      const claimId = _.get(detail, "elements[0].claim_id") || "";
      dispatch("onLoading");
      dispatch("closeDialogNoti");

      const res = {};

      const params = {
        claimId,
        elements: [
          {
            typeId: 520,
            typeCode: "action_element",
            extraInfo: {
              documents: [{ path: currentFilePdf?.path || "" }],
              user: { userNote: { id: user?.id, fullname: user?.fullname } },
            },
            text: dialogSendApproval?.note || dialogNote?.note || "",
          },
          {
            claimId,
            id: currentFilePdf?.id_element,
            typeId: 521,
            typeCode: "note_pdf",
            text: currentFilePdf?.text || "",
            // text: currentFilePdf?.text || dialogSendApproval?.note || "",
          },
        ],
      };

      const sendApi = await requestApprove(systemType, params);

      if (
        sendApi?.data?.statusCode === 404 &&
        sendApi?.data?.messageCode == "user.not.found.to.assign"
      ) {
        return {
          loading: false,
          dialogNoti: {
            ...state.dialogNoti,
            open: true,
            type: "error",
            textContent: ["Không tìm thấy tài để gán yêu cầu bồi thường"],
          },
        };
      }

      if (sendApi instanceof Error) {
        dispatch({
          type: "openDialogNoti",
          typeDialog: "error",
          textContent: [
            "Phê duyệt yêu cầu bồi thường của bạn",
            "đã gửi đi không thành công",
          ],
        });
        return { loading: false };
      }

      if (sendApi?.data?.statusCode === 200) {
        res.currentFilePdf = {};
        const indexItemChangStatusId = rowsHistoryTable1.findIndex(
          (i) => i.id == currentFilePdf.id_element
        );

        if (indexItemChangStatusId > -1) {
          rowsHistoryTable1[indexItemChangStatusId] = {
            ...rowsHistoryTable1[indexItemChangStatusId],
            status_id: 3,
          };
        }
        detail.statusId = sendApi?.data?.result?.status_id;
        dispatch("closeDialogNote");
        dispatch({
          type: "openDialogNoti",
          typeDialog: "success",
          textContent: [
            "Yêu cầu phê duyệt của bạn đã được ",
            "gửi đi thành công",
          ],
        });
      }

      dispatch({ type: "onChangeTabs", value: 4 });
      return {
        ...res,
        loading: false,
        detail,
        // tabsDetail: { ...tabsDetail, value: 4, key: "recordkeeping-talbe" },
        dialogSendApproval: { ...dialogSendApproval, open: false },
      };
    },

    //* func run useEffect from file RecordkeepingTalbe
    inittalHistory: async () => {
      const { detail, snackbar, currentFilePdf } = state;

      dispatch("onLoading");
      const res = {};

      const [dataApi, dataDetailClaim] = await Promise.all([
        getHistoryClaim(systemType, {
          certNum: detail?.certNum,
          limit: 100,
          offset: 0,
        }),
        getDetailClaim(systemType, id),
      ]);

      if (dataApi instanceof Error || dataDetailClaim instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại ",
          },
        };
      }

      if (dataApi.data.statusCode === 200) {
        res.rowsHistory = dataApi?.data?.result?.data || [];
      }
      if (dataDetailClaim.data.statusCode === 200) {
        const temp = _.get(dataDetailClaim, "data.result") || {};
        res.detail = temp;
        res.rowsHistoryTable1 = (temp.elements || []).filter((item) => {
          return (
            item.type_id == 521 &&
            [2, 3, 5, 7, 8, 9, 10].includes(item.status_id) &&
            !!(_.get(item, "extra_info.documents[0].path") || null)
          );
        });

        const data_element_note = temp?.elements?.filter(
          (i) =>
            i.type_id === 520 &&
            i.status_id != 2 &&
            _.get(i, "extra_info.documents[0].path") ===
              _.get(res.rowsHistoryTable1, "[0].extra_info.documents[0].path")
        );

        res.currentFilePdf = _.isEmpty(res.rowsHistoryTable1[0])
          ? {}
          : {
              ...currentFilePdf,
              id_element: res.rowsHistoryTable1[0].id,
              path: _.get(
                res.rowsHistoryTable1[0],
                "extra_info.documents[0].path"
              ),
              data_element: { ...res.rowsHistoryTable1[0] },
              data_element_note: [...data_element_note.reverse()],
            };
      }

      return { loading: false, ...res };
    },

    //* func to upload payment "uỷ nhiệm chi"
    uploadComplete: async () => {
      const { files } = action;
      const { detail, snackbar } = state;

      const claimId = _.get(detail, "elements[0].claim_id") || "";
      const data = new FormData();
      data.append("document", files);
      const elementPDF = detail?.elements?.find(
        (i) => i.type_id == 521 && (i.status_id == 9 || i.status_id == 5)
      );
      const urlPDF = _.get(elementPDF, "extra_info.documents[0].path");

      // if (urlPDF) {
      //   return {
      //     snackbar: {
      //       ...snackbar,
      //       open: true,
      //       type: "error",
      //       message:
      //         "Không thấy FILE PDF để thực hiện. Vui lòng liên hệ vệ vs kỹ thuật viên để kiểm tra. Xin cảm ơn",
      //     },
      //   };
      // }

      dispatch("onLoading");
      const pdfFile = await getFileByUrl(urlPDF);
      const pdfArrayBuffer = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfArrayBuffer);

      const blobImg = await converToPNG(files);
      const imageArrayBuffer = await blobImg.arrayBuffer();
      const imagePDF = await pdfDoc.embedPng(imageArrayBuffer);

      const newPage = pdfDoc.addPage();
      newPage.drawImage(imagePDF, {
        // x: newPage.getWidth() / 2 - imagePDF.width / 2,
        x: (newPage.getWidth() - newPage.getWidth() * 0.9) / 2,
        y:
          (newPage.getHeight() -
            imagePDF.height * ((newPage.getWidth() * 0.9) / imagePDF.width)) /
          2,
        width: newPage.getWidth() * 0.9,
        height: imagePDF.height * ((newPage.getWidth() * 0.9) / imagePDF.width),
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const myFile = new File([blob], `myFile.pdf`);
      const dataPDF = new FormData();
      dataPDF.append("document", myFile);

      const [dataApi, apiPdf] = await Promise.all([
        uploadImg(data),
        uploadImg(dataPDF),
      ]);

      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        const params = {
          claimId,
          elements: [
            {
              typeId: 522,
              typeCode: "action_element_complete",
              extraInfo: {
                documents: [
                  {
                    size: _.get(dataApi, "data.result[0].size"),
                    path: _.get(dataApi, "data.result[0].path"),
                  },
                ],
                // text: "",
              },

              text: "",
            },
          ],
        };

        const elementPdfParams = [
          {
            id: elementPDF?.id || null,
            claimId: claimId || null,
            typeId: elementPDF.type_id,
            typeCode: elementPDF.type_code,
            text: elementPDF.text || "",
            extraInfo: {
              documents: [
                {
                  size: _.get(apiPdf, "data.result[0].size"),
                  path: _.get(apiPdf, "data.result[0].path"),
                },
              ],
            },
          },
        ];

        const sendApi = await complete(systemType, params);
        const [apiAddNote, deleteS3] = await Promise.all([
          addNote(systemType, {
            claimId: elementPDF.claim_id,
            elements: elementPdfParams,
          }),
          deleteS3Document({
            action_code: "document_delete",
            extra_info: { url: urlPDF },
          }),
        ]);

        if (sendApi instanceof Error) {
          dispatch({
            type: "openDialogNoti",
            typeDialog: "error",
            textContent: ["Upload uỷ nhiệm chi không thành công"],
          });
          return { loading: false };
        }

        if (sendApi?.data?.statusCode === 200) {
          const [dataApi, deleteS3] = await Promise.all([
            addNote(systemType, {
              claimId: elementPDF.claim_id,
              elements: elementPdfParams,
            }),
            deleteS3Document({
              action_code: "document_delete",
              extra_info: { url: urlPDF },
            }),
          ]);

          dispatch({
            type: "openDialogNoti",
            typeDialog: "success",
            textContent: ["Upload uỷ nhiệm chi thành công"],
          });

          const dataDetailClaim = await getDetailClaim(systemType, id);

          if (dataDetailClaim?.data?.statusCode === 200) {
            const temp = _.get(dataDetailClaim, "data.result") || {};
            res.detail = temp;
          }
        }

        return {
          ...res,
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "success",
            message: "upfile thành công",
          },
        };
      }

      return {
        loading: false,
        snackbar: {
          ...snackbar,
          open: true,
          type: "error",
          message: "Đã xảy ra lỗi. Xin vui lòng thử lại ",
        },
      };
    },

    //* func to call API when you submit approve of user level 2->5
    submitApprove: async () => {
      const {
        detail,
        dialogNote,
        currentFilePdf,
        rowsHistoryTable1,
        snackbar,
      } = state;
      const claimId = _.get(detail, "elements[0].claim_id");
      const sentToSuperiors =
        (currentFilePdf?.data_element?.extra_info?.approve_info
          ?.approve_amount || 0) > approve_to;

      dispatch("onLoading");
      dispatch("closeDialogNote");
      const params = {
        claimId,
        elements: [
          {
            typeId: 520,
            typeCode: "action_element",
            text: dialogNote?.note || "",
            extraInfo: {
              documents: [{ path: currentFilePdf?.path || "" }],
              user: { userNote: { id: user?.id, fullname: user?.fullname } },
            },
          },
          {
            typeId: 521,
            claimId,
            typeCode: "note_pdf",
            id: currentFilePdf?.id_element || null,
          },
        ],
      };

      const dataApi = await approve(systemType, params);

      const res = {};
      if (
        dataApi?.data?.statusCode === 404 &&
        dataApi?.data?.messageCode == "user.not.found.to.assign"
      ) {
        return {
          loading: false,
          dialogNoti: {
            ...state.dialogNoti,
            open: true,
            type: "error",
            textContent: ["Không tìm thấy tài để gán yêu cầu bồi thường"],
          },
        };
      }

      if (dataApi?.data?.statusCode === 200) {
        res.currentFilePdf = {};
        const temp = dataApi?.data?.result || {};
        const indexItemChangStatusId = rowsHistoryTable1.findIndex(
          (i) => i.id == currentFilePdf.id_element
        );
        const elementsApprove = temp?.elements?.find(
          (i) => i.id === currentFilePdf.id_element
        );

        if (indexItemChangStatusId > -1) {
          rowsHistoryTable1[indexItemChangStatusId] = {
            ...rowsHistoryTable1[indexItemChangStatusId],
            status_id: elementsApprove?.status_id,
          };
        }
        detail.statusId = temp?.status_id;
        detail.elements = [...temp?.elements];
        detail.userFullnameAssign = temp?.user_fullname_assign;
        detail.userFullnameReview = temp?.user_fullname_review;
        detail.userIdAssign = temp?.user_id_assign;
        detail.userIdReview = temp?.user_id_review;

        res.dialogNoti = {
          ...state.dialogNoti,
          open: true,
          type: "success",
          textContent: sentToSuperiors
            ? ["Yêu cầu phê duyệt của bạn đã được", "gửi đi thành công"]
            : [
                "Phê duyệt yêu cầu bồi thường của bạn",
                "đã được gửi đến khách hàng",
              ],
        };

        if (!sentToSuperiors) {
          dispatch("buildFilePDF");
        }
      }

      if (dataApi instanceof Error) {
        return {
          loading: false,
          dialogNoti: {
            ...state.dialogNoti,
            open: true,
            type: "error",
            textContent: sentToSuperiors
              ? ["Yêu cầu phê duyệt của bạn đã gửi đi", "không thành công"]
              : [
                  "Phê duyệt yêu cầu bồi thường của bạn",
                  "đã gửi đi không thành công",
                ],
          },
        };
      }

      return { loading: false, ...res };
    },

    //* func to call API when you submit refuse of user level 2->5
    submitRefuse: async () => {
      const { detail, dialogNote, currentFilePdf, rowsHistoryTable1 } = state;
      const claimId = _.get(detail, "elements[0].claim_id");

      dispatch("onLoading");
      dispatch("closeDialogNote");

      const params = {
        claimId,
        elements: [
          {
            typeId: 520,
            typeCode: "action_element",
            text: dialogNote?.note || "",
            extraInfo: {
              documents: [{ path: currentFilePdf?.path || "" }],
              user: { userNote: { id: user?.id, fullname: user?.fullname } },
            },
          },
          {
            typeId: 521,
            claimId,
            typeCode: "note_pdf",
            // text: dialogNote?.note || "",
            id: currentFilePdf?.id_element || null,
          },
        ],
      };

      const dataApi = await reject(systemType, params);

      const res = {};
      if (dataApi?.data?.statusCode === 200) {
        res.currentFilePdf = {};
        const temp = dataApi?.data?.result || {};
        const indexItemChangStatusId = rowsHistoryTable1.findIndex(
          (i) => i.id == currentFilePdf.id_element
        );

        const elementsApprove = temp?.elements?.find(
          (i) => i.id === currentFilePdf.id_element
        );

        if (indexItemChangStatusId > -1) {
          rowsHistoryTable1[indexItemChangStatusId] = {
            ...rowsHistoryTable1[indexItemChangStatusId],
            status_id: elementsApprove?.status_id,
          };
        }
        detail.statusId = temp?.status_id;
        detail.elements = temp?.elements;

        res.dialogNoti = {
          ...state.dialogNoti,
          open: true,
          type: "success",
          textContent: [
            "Từ chối phê duyệt hồ sơ của bạn đã",
            "được gửi đi thành công",
          ],
        };
      }

      if (dataApi instanceof Error) {
        return {
          loading: false,
          dialogNoti: {
            ...state.dialogNoti,
            open: true,
            type: "error",
            textContent: [
              "Từ chối phê duyệt hồ sơ của bạn đã",
              "được gửi đi không thành công",
            ],
          },
        };
      }

      return { loading: false, ...res };
    },

    onChangeTabRightStatus: () => ({ tabRight: action.newValue }),

    openDialogNoti: () => {
      const { typeDialog, name, textContent } = action;
      const { listCommentLinceseAdd, snackbar } = state;

      if (name == "submitAdditionalRequest") {
        const check =
          (
            (listCommentLinceseAdd.find((i) => i.isNewCmt) || {})?.comments ||
            []
          ).length > 0;

        if (!check) {
          return {
            snackbar: {
              ...snackbar,
              open: true,
              type: "error",
              message: "Vui lòng thêm yêu cầu bổ sung",
            },
          };
        }
      }

      return {
        dialogNoti: {
          open: true,
          type: typeDialog,
          name: name || "",
          textContent: textContent,
        },
      };
    },

    closeDialogNoti: () => ({
      dialogNoti: { ...state.dialogNoti, open: false, name: "" },
    }),

    openDialogNote: () => {
      const { name } = action;
      const { snackbar, rowsHistoryTable1 } = state;
      if (name === "sendRequestApprpve") {
        let checkSended = false;
        rowsHistoryTable1.forEach((i) => {
          if (checkSended) return;
          checkSended = [3, 4, 5, 6, 8].includes(i.status_id);
        });
        if (checkSended) {
          return {
            snackbar: {
              ...snackbar,
              open: true,
              type: "error",
              message: "Bạn đã gửi yêu cầu duyệt cho File khác",
            },
          };
        }
      }

      const getTitle = () => {
        switch (name) {
          case "addNote":
            return "Thêm ghi chú";
          case "submitRefuse":
            return "Nội dung từ chối";
          case "submitApprove":
            return "Nội dung phê duyệt";
          case "sendRequestApprpve":
            return "Nội dung yêu cầu phê duyệt";
        }
      };

      return {
        dialogNote: {
          ...state.dialogNote,
          open: true,
          title: getTitle(),
          name,
          note: "",
        },
      };
    },

    onChangeNoteRightTabs: () => ({
      dialogNote: {
        ...state.dialogNote,
        note: action.value || "",
      },
    }),

    addNote: async () => {
      const { dialogNote, detail, listNote, snackbar } = state;
      const claimId = _.get(detail, "elements[0].claim_id") || "";
      dispatch("onLoading");

      if (_.isEmpty(dialogNote.note)) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "warning",
            message: "Vui lòng nhập thông tin cần note",
          },
        };
      }

      const dataApi = await addNote(systemType, {
        claimId: claimId,
        elements: [
          {
            typeId: 522,
            typeCode: "note",
            text: dialogNote?.note || "",
          },
        ],
      });

      if (dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi. Xin vui lòng thử lại ",
          },
        };
      }

      listNote.push({
        created_date: moment().format(),
        extra_info: {
          user: {
            userNote: {
              fullname: user?.fullname || "",
              level_id: level_id,
            },
          },
        },
        text: dialogNote?.note || "",
      });
      dispatch("closeDialogNote");

      return {
        loading: false,
        listNote,
        snackbar: {
          ...snackbar,
          open: true,
          type: "success",
          message: "Thêm ghi chú thành công",
        },
      };
    },

    changeMoney: () => {
      const { finalFeesTotalApprove } = action;
      const { noteUseLV1 } = state;

      if (+noteUseLV1?.approvedPercent === 100) {
        return {
          noteUseLV1: {
            ...noteUseLV1,
            approvedPercent: 100,
            amount: finalFeesTotalApprove,
          },
        };
      }

      return {
        noteUseLV1: {
          ...noteUseLV1,
          approvedPercent: "",
          amount: "",
        },
      };
    },

    closeDialogNote: () => ({
      dialogNote: {
        ...state.dialogNote,
        open: false,
        note: "",
      },
    }),

    closeDialogInsuranceInfo: async () => ({
      dialogInsuranceInfo: { ...state.dialogInsuranceInfo, open: false },
    }),

    //* open modal detial PDF
    openDialogDetailPDF: () => {
      const { item, name } = action;
      const { snackbar, currentFilePdf } = state;
      const path =
        name == "current_file"
          ? currentFilePdf?.path
          : _.get(item, "extra_info.documents[0].path") || null;
      if (!path) {
        return {
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi khi mở file, vui lòng thử lại",
          },
        };
      }

      window.open(path);

      // return { dialogDetailPDF: { open: true, path: path } };
    },

    chooseDataFilePDF: () => {
      const { item } = action;
      const { currentFilePdf, snackbar, detail } = state;

      // const validate =
      //   level_id == 1 ? item.status_id != 2 : item.status_id != 3;

      // if (validate) {
      //   return {
      //     currentFilePdf: {},
      //     snackbar: {
      //       ...snackbar,
      //       open: true,
      //       type: "error",
      //       message: "Trạng thái file không hợp lệ để gửi yêu cầu phê duyệt",
      //     },
      //   };
      // }

      const data_element_note = detail?.elements?.filter(
        (i) =>
          i.type_id === 520 &&
          i.status_id != 2 &&
          _.get(i, "extra_info.documents[0].path") ===
            _.get(item, "extra_info.documents[0].path")
      );

      const data =
        item.id === currentFilePdf?.data_element?.id
          ? {}
          : {
              ...currentFilePdf,
              id_element: item.id,
              path: _.get(item, "extra_info.documents[0].path"),
              data_element: { ...item },
              data_element_note: [...data_element_note.reverse()],
            };

      return {
        currentFilePdf: data,
      };
    },

    closeDialogDetailPDF: () => ({ dialogDetailPDF: false }),

    openDialogFullWidth: () => ({ openDialogFullWidth: true }),

    closeDialogFullWidth: () => ({ openDialogFullWidth: false }),

    onchangeNoteSendApproval: () => {
      const { value } = action;
      const { dialogSendApproval } = state;
      return { dialogSendApproval: { ...dialogSendApproval, note: value } };
    },

    closeDialogSendApproval: () => ({
      dialogSendApproval: {
        ...state.dialogSendApproval,
        open: false,
        note: "",
      },
      currentFilePdf: {},
    }),

    openDialogshowImg: () => ({
      dialogShowImg: { ...state.dialogShowImg, open: true, path: action.path },
    }),

    closeDialogShowImg: () => ({
      dialogShowImg: { ...state.dialogShowImg, open: false },
    }),

    openSnackbar: () => ({
      snackbar: {
        ...state.snackbar,
        open: true,
        type: action.typeSnackbar,
        message: action.message,
      },
    }),

    closeSnackbar: () => ({ snackbar: { ...state.snackbar, open: false } }),

    onLoading: () => {
      return { loading: true };
    },

    progressLoading: () => {
      const { open, value } = action;
      return { progressLoading: { open, value } };
    },

    changeButtonTabs: () => ({ tabButton: action.name }),

    onLoadingClaimLicense: () => ({ loadingClaimLicense: true }),

    setShowImgLicens: () => {
      const { name } = action;
      const { imgLicenseShow, detail } = state;

      if (!name) {
        return { imgLicenseShow: { ...imgLicenseShow, open: false } };
      }

      const keys = [name];
      if (name == "bill") {
        keys.push(
          "examination_cost_bill",
          "medicine_bill",
          "re-examination_bill"
        );
      }
      const temp = [];

      keys.forEach((i) => {
        const elementsByTypecode = detail?.elements.find(
          (el) => i == el.type_code
        );

        if (elementsByTypecode?.extra_info?.documents?.length > 0) {
          const listImg = elementsByTypecode?.extra_info?.documents
            ?.filter((i) => !!i.path)
            ?.map((e) => e.path);
          temp.push(...listImg);
        }
        return;
      });

      const checkTypecode = [];

      detail?.elements?.forEach((i) => {
        if (
          i?.extra_info?.documents?.length > 0 &&
          i.type_code !== "sign" &&
          !keys.includes(i.type_code) &&
          !checkTypecode.includes(i.type_code)
        ) {
          const listImg = i?.extra_info?.documents
            ?.filter((i) => !!i.path)
            ?.map((e) => e.path);
          temp.push(...listImg);
        }

        checkTypecode.push(i.type_code);
        return;
      }, []);

      return {
        imgLicenseShow: {
          list: temp,
          path: temp[0] || "",
          indexImg: 1,
          open: !imgLicenseShow.open,
        },
      };
    },

    onChangePictureBox: () => {
      const { typeAction } = action;
      const { pictureBox } = state;
      switch (typeAction) {
        case "rotated-left":
          return {
            pictureBox: {
              ...pictureBox,
              rotate: (pictureBox.rotate || 0) - 90,
            },
          };
        case "rotated-right":
          return {
            pictureBox: {
              ...pictureBox,
              rotate: (pictureBox.rotate || 0) + 90,
            },
          };
        case "zoom-in":
          return {
            pictureBox: {
              ...pictureBox,
              scale: (pictureBox.scale || 1) + 0.25,
            },
          };
        case "zoom-out":
          return {
            pictureBox: {
              ...pictureBox,
              scale: (pictureBox.scale || 1) - 0.25,
            },
          };
      }
    },

    onChangeIndexImg: () => {
      const { name } = action;
      const { imgLicenseShow } = state;

      if (name === "next") {
        const indexImg =
          imgLicenseShow.indexImg === imgLicenseShow.list.length
            ? 1
            : imgLicenseShow.indexImg + 1;
        imgLicenseShow.indexImg = indexImg;
        imgLicenseShow.path = imgLicenseShow.list[indexImg - 1] || "";
      } else {
        const indexImg =
          imgLicenseShow.indexImg === 1
            ? imgLicenseShow.list.length
            : imgLicenseShow.indexImg - 1;
        imgLicenseShow.indexImg = indexImg;
        imgLicenseShow.path = imgLicenseShow.list[indexImg - 1] || "";
      }

      return { imgLicenseShow };
    },

    changeStatusFilepdf: async () => {
      const { name } = action;
      const { popoverbutton, snackbar, rowsHistoryTable1, detail } = state;
      const params = {
        claimElementId: popoverbutton?.data?.id,
      };

      const paramsCancel = {
        claimId: `${detail.id || ""}`,
        elements: [
          {
            // id: popoverbutton?.data?.id,
            claimId: `${detail.id || ""}`,
            typeId: 520,
            typeCode: "note_pdf",
            text: popoverbutton?.data?.id,
            extraInfo: {
              documents:
                popoverbutton?.data?.extra_info?.documents ||
                popoverbutton?.data?.extraInfo?.documents ||
                [],
            },
          },
          {
            id: popoverbutton?.data?.id,
            claimId: `${detail.id || ""}`,
            typeId: 521,
            typeCode: "note_pdf",
            text: "",
            extraInfo: {
              documents:
                popoverbutton?.data?.extra_info?.documents ||
                popoverbutton?.data?.extraInfo?.documents ||
                [],
            },
          },
        ],
      };

      dispatch("onLoading");
      const res = {};

      switch (name) {
        case "invalidPDF":
          res.dataApi = await invalidPDF(userTypeFromProfile, paramsCancel);
          break;
        case "reconfirm":
          // res.dataApi = await reconfirm(params);
          dispatch({ type: "closePopoverButton" });
          dispatch({
            type: "openDialogNoti",
            typeDialog: "warning",
            name: "submitReconfirm",
            textContent: [
              "Bạn có chắc chắn muốn gửi xác nhận",
              "bồi thường đến khách hàng",
            ],
          });
          break;
        case "deletePDF":
          const url =
            _.get(popoverbutton, "data.extra_info.documents[0].path") || null;
          if (url) {
            deleteS3Document({
              action_code: "document_delete",
              extra_info: { url: url },
            });
          }

          res.dataApi = await deletePDF(params);
          break;
        case "cancelPDF":
          res.dataApi = await cancelPDF(userTypeFromProfile, paramsCancel);
          break;
        default:
          break;
      }

      if (res.dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      if (res?.dataApi?.data?.statusCode == 200) {
        switch (name) {
          case "invalidPDF":
            rowsHistoryTable1[popoverbutton.index] = {
              ...popoverbutton.data,
              status_id: 10,
            };
            break;
          case "deletePDF":
            rowsHistoryTable1.splice(popoverbutton.index, 1);
            break;
          case "cancelPDF":
            detail.statusId = 2;
            rowsHistoryTable1[popoverbutton.index] = {
              ...popoverbutton.data,
              status_id: 8,
            };
            break;
          default:
            break;
        }

        dispatch({ type: "closePopoverButton" });
        res.snackbar = {
          ...snackbar,
          open: true,
          type: "success",
          message: res?.dataApi?.data?.message || "Thao tác thành công",
        };
      }

      return {
        detail,
        rowsHistoryTable1,
        snackbar: { ...snackbar, ...res.snackbar },
        loading: false,
      };
    },

    submitReconfirm: async () => {
      const { popoverbutton, snackbar, detail } = state;
      const params = {
        claimId: `${detail.id || ""}`,
        elements: [
          {
            claimId: `${detail.id || ""}`,
            typeId: 520,
            typeCode: "action_element",
            text: popoverbutton?.data?.id,
            extraInfo: {
              documents:
                popoverbutton?.data?.extra_info?.documents ||
                popoverbutton?.data?.extraInfo?.documents ||
                [],
            },
          },
          {
            id: popoverbutton?.data?.id,
            claimId: `${detail.id || ""}`,
            typeId: 521,
            typeCode: "note_pdf",
            text: "",
            extraInfo: {
              documents:
                popoverbutton?.data?.extra_info?.documents ||
                popoverbutton?.data?.extraInfo?.documents ||
                [],
            },
          },
        ],
      };

      dispatch("onLoading");
      const res = {};
      const dataApi = await reconfirm(userTypeFromProfile, params);
      if (res.dataApi instanceof Error) {
        return {
          loading: false,
          snackbar: {
            ...snackbar,
            open: true,
            type: "error",
            message: "Đã xảy ra lỗi, xin vui lòng thử lại",
          },
        };
      }

      if (dataApi?.data?.statusCode == 200) {
        res.detail = {
          ...detail,
          statusId: dataApi?.data?.result?.status_id,
          elements: dataApi?.data?.result?.elements,
        };
        dispatch({
          type: "openDialogNoti",
          typeDialog: "success",
          name: "submitReconfirm",
          textContent: ["Xác nhận bồi thường đã được gửi đến", "khách hàng"],
        });
      }
      return { loading: false, ...res };
    },

    openPopoverButton: () => {
      const { e, item, rowIdx } = action;
      const { popoverbutton } = state;

      return {
        popoverbutton: {
          ...popoverbutton,
          open: true,
          anchorEl: e?.currentTarget,
          statusItem: item?.status_id || null,
          data: item,
          index: rowIdx,
        },
      };
    },

    closePopoverButton: () => {
      const { popoverbutton } = state;
      return {
        popoverbutton: {
          ...popoverbutton,
          open: false,
          anchorEl: null,
        },
      };
    },

    buildFilePDF: async () => {
      const {
        detail,
        noteUseLV1,
        currentFilePdf,
        rowsHistoryTable1,
        insuranceTypes,
      } = state;
      const saveField = { ...currentFilePdf };
      const temps = [];
      const listTypeId = [
        500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513,
        514, 515, 523, 524, 525, 528,
      ];

      const listImg = (detail?.elements || []).reduce((array, item) => {
        if (listTypeId.includes(+item?.type_id)) {
          const get_img_in_item = (item?.extra_info?.documents || [])
            .filter((i) => !!i.path)
            .map((i) => i.path);
          return [...array, ...get_img_in_item];
        }
        return array;
      }, []);

      for (let iFor = 0; iFor < listImg.length; iFor++) {
        const dataApi = await getFileByUrl(listImg[iFor]);

        if (dataApi) {
          const newImg = await resizeFile(dataApi);
          newImg && temps.push(newImg);
        }
      }
      const blobPdf = await new pdf(
        ExportClaimPDF({
          detail: detail,
          noteUseLV1: noteUseLV1,
          approveAmount: noteUseLV1?.amount,
          listImgBase64PDFfile: [...temps],
          insuranceTypes: insuranceTypes,
        })
      ).toBlob();

      if (!blobPdf) {
        return;
      }

      const myFile = new File([blobPdf], `myFile.pdf`);
      const data = new FormData();
      data.append("document", myFile);

      const cdnPdfApi = await uploadImg(data);

      if (cdnPdfApi instanceof Error) {
        return;
      }

      const newUrlFilePdf = _.get(cdnPdfApi, "data.result[0].path") || null;
      const elementPdfParams = [
        {
          id: currentFilePdf?.data_element?.id || null,
          claimId: currentFilePdf?.data_element?.claim_id || null,
          typeId: currentFilePdf?.data_element?.type_id,
          typeCode: currentFilePdf?.data_element?.type_code,
          text: currentFilePdf?.data_element?.text || "",
          extraInfo: {
            ...currentFilePdf?.data_element?.extra_info,
            documents: [
              {
                size: _.get(cdnPdfApi, "data.result[0].size"),
                path: newUrlFilePdf,
              },
            ],
          },
        },
      ];

      const apiAddNote = await addNote(systemType, {
        claimId: currentFilePdf?.data_element?.claim_id,
        elements: elementPdfParams,
      });

      if (apiAddNote?.data?.statusCode === 200) {
        deleteS3Document({
          action_code: "document_delete",
          extra_info: {
            url:
              _.get(
                currentFilePdf,
                "data_element.extra_info.documents[0].path"
              ) || null,
          },
        });

        const index = rowsHistoryTable1.findIndex(
          (i) => i.id === saveField?.data_element?.id
        );
        _.set(
          rowsHistoryTable1,
          `[${index}].extra_info.documents[0].path`,
          newUrlFilePdf
        );
      }

      return { rowsHistoryTable1 };
      //
    },
  };

  return cases[action.type] && cases[action.type];
}
