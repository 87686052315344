import React, { useEffect, useLayoutEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ThemeProvider, createTheme } from "@mui/material";
import moment from "moment";

import styleClaim from "./styleClaim";
import {
  listTypeOfRisk,
  converDate,
  typeOfIndimnity,
  formatDate_Y_M_D,
} from "../../utils";

export default function ProfileInformation(props) {
  const { state, dispatch } = props;
  const classes = styleClaim();

  const { profileInformationExpan, insuredPersonInformation, dialogUpdate } =
    state;
  const typeOfTreatment = typeOfIndimnity.find(
    (i) => i.id === insuredPersonInformation?.typeOfIndimnity || ""
  );

  const renderData = [
    {
      title: "Nơi điều trị",
      value: insuredPersonInformation?.treatmentPlace,
      color: "#3961A0",
    },
    {
      title: "Khoa điều trị",
      value: insuredPersonInformation?.departmentTreatment,
      color: "#3961A0",
    },
    {
      title: "Loại hình điều trị",
      value: typeOfTreatment?.title || "",
      color: "#3961A0",
    },
    {
      title: "Bác sỹ điều trị",
      value: insuredPersonInformation?.doctorName,
      color: "#3961A0",
      capitalize: true,
    },
    {
      title: "Đến khám ",
      value: formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
        ? moment(
            formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
          ).format("HH:mm - DD/MM/YYYY")
        : "",
      color: "#686868",
    },
    {
      title: "Ngày vào viện ",
      value: formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
        ? moment(
            formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
          ).format("HH:mm - DD/MM/YYYY")
        : "",
      color: "#686868",
    },
    {
      title: "Ngày ra viện ",
      value: formatDate_Y_M_D(insuredPersonInformation?.dateOutOfHospital)
        ? moment(
            formatDate_Y_M_D(insuredPersonInformation?.dateOutOfHospital)
          ).format("HH:mm - DD/MM/YYYY")
        : "",
      color: "#686868",
    },
    {
      title: "Tổng ngày điều trị ",
      value: insuredPersonInformation?.timeInHospital,
      color: "#686868",
    },
    {
      title: "Họ và tên ",
      value: insuredPersonInformation?.insuredName,
      color: "#686868",
      capitalize: true,
    },
    {
      title: "Giới tính ",
      value:
        insuredPersonInformation?.insuredGender === 1
          ? "Nam"
          : insuredPersonInformation?.insuredGender === 0
          ? "Nữ"
          : "",
      color: "#686868",
    },
    {
      title: "Ngày sinh",
      value: formatDate_Y_M_D(insuredPersonInformation?.insuredDob)
        ? moment(formatDate_Y_M_D(insuredPersonInformation?.insuredDob)).format(
            "DD/MM/YYYY"
          )
        : "",
      color: "#686868",
    },
    {
      title: "Số điện thoại",
      value: insuredPersonInformation?.insuredPhone,
      color: "#686868",
    },
    {
      title: "CMND/ CCCD ",
      value: insuredPersonInformation?.insuredPrivateId,
      color: "#686868",
    },
    {
      title: "Email ",
      value: insuredPersonInformation?.insuredEmail,
      color: "#686868",
    },
    {
      title: "Địa chỉ ",
      value: insuredPersonInformation?.insuredAddress,
      color: "#686868",
    },
    {
      title: "Nơi khai báo ",
      value: insuredPersonInformation?.merchantTitle || "",
      color: "#686868",
    },
  ];

  useEffect(() => {
    document.getElementById("box-profile-information-div").style.height =
      profileInformationExpan
        ? 0
        : `${
            document
              ?.getElementById("box-profile-information")
              ?.getBoundingClientRect()?.height + 16
          }px`;
  }, [dialogUpdate?.open, insuredPersonInformation, profileInformationExpan]);

  useLayoutEffect(() => {
    function updateSize() {
      document.getElementById("box-profile-information-div").style.height =
        profileInformationExpan
          ? 0
          : `${
              document
                ?.getElementById("box-profile-information")
                ?.getBoundingClientRect()?.height + 16
            }px`;
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [dialogUpdate?.open, insuredPersonInformation]);

  return (
    <div
      className={classes.container_profile_information}
      id="box-profile-information-div"
      style={{
        opacity: profileInformationExpan ? 0 : 1,
        padding: profileInformationExpan ? 0 : "",
        margin: profileInformationExpan ? "8px 0" : "",
        MozTransition: "all 0.7s ease",
        WebkitTransition: "all 0.7s ease",
        OTransition: "all 0.7s ease",
        transition: "all 0.7s ease",
      }}
    >
      <ThemeProvider
        theme={createTheme({
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 900,
              lg: 1200,
              xl: 1536,
              xxl: 2250,
            },
          },
        })}
      >
        <Grid container spacing={2} id="box-profile-information">
          {renderData.map((item, index) => (
            <Grid item key={index} xs={6} sm={4} md={4} lg={3} xl={3} xxl={2}>
              <div className="item-grid-profile-information">
                <div className="lable">{item.title}</div>
                <div
                  style={{
                    color: "#686868",
                    fontWeight: "600",
                    textTransform: item.capitalize ? "capitalize" : "",
                  }}
                >
                  {item.value}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </ThemeProvider>
    </div>
  );
}
