import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";
import { MenuItem } from "@mui/material";
import { DatePicker } from "antd";

import styleDialog from "./styleDialog";
import { converDate, formatDate_Y_M_D, typeOfIndimnity } from "../../../utils";
import { TextField } from "../../../overrideMui";

export default function InforRecords(props) {
  const { dispatch, state, name } = props;
  const classes = styleDialog();

  const { insuredPersonInformation } = state;

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const renderProfileInformation = [
    {
      name: "typeOfIndimnity",
      label: "Loại hình điều trị",
      placeholder: "Nhập thông tin",
      type: "select",
      disabled: true,
      value: insuredPersonInformation?.typeOfIndimnity || "",
      menu: typeOfIndimnity.map((i) => ({ value: i.id, label: i.title })),
    },
    {
      name: "insuredName",
      label: "Họ và tên ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.insuredName || "",
      capitalize: true,
    },
    {
      name: "treatmentPlace",
      label: "Nơi điều trị ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.treatmentPlace || "",
    },
    {
      name: "insuredGender",
      label: "Giới tính ",
      placeholder: "Nhập thông tin",
      type: "select",
      value: insuredPersonInformation?.insuredGender,
      menu: [
        { value: 0, label: "Nữ" },
        { value: 1, label: "Nam" },
      ],
    },
    {
      name: "departmentTreatment",
      label: "Khoa điều trị ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.departmentTreatment || "",
    },
    {
      name: "insuredDob",
      label: "Năm sinh ",
      placeholder: "Nhập thông tin",
      type: "date",
      value: formatDate_Y_M_D(insuredPersonInformation?.insuredDob)
        ? moment(
            formatDate_Y_M_D(insuredPersonInformation?.insuredDob)
          ).format()
        : null,
    },
    {
      name: "doctorName",
      label: "Bác sỹ điều trị ",
      placeholder: "Nhập thông tin",
      type: "input",
      capitalize: true,
      value: insuredPersonInformation?.doctorName || "",
    },
    {
      name: "insuredPhone",
      label: "Số điện thoại ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.insuredPhone || "",
    },
    {
      name: "dateOfHospital",
      label: "Đến khám ",
      placeholder: "Nhập thông tin",
      type: "date",
      time: true,
      disabledDate: (e) => {
        if (insuredPersonInformation.typeOfRisk == 1 && !!e) {
          return (
            moment(e.format("YYYY/MM/DD")).diff(
              moment(moment().format("YYYY/MM/DD")),
              "days"
            ) > -1
          );
        }
        if (insuredPersonInformation.typeOfRisk == 2 && !!e) {
          return (
            moment(e.format("YYYY/MM/DD")).diff(
              moment(moment().format("YYYY/MM/DD")),
              "days"
            ) > 0
          );
        }
        return (
          moment(e?.format("YYYY/MM/DD")).diff(
            moment(moment().format("YYYY/MM/DD")),
            "days"
          ) > 0
        );
      },
      disabledTime: (e) => {
        const currentDay =
          moment(e?.format("YYYY/MM/DD")).diff(
            moment(moment().format("YYYY/MM/DD")),
            "days"
          ) == 0;

        if (currentDay) {
          const currentHours = moment().hour();
          const currentMinutes = moment().minute();
          return {
            disabledHours: () => range(currentHours + 1, 24),
            disabledMinutes: () => range(currentMinutes, 60),
          };
        }
        return null;
      },
      value: converDate(insuredPersonInformation?.dateOfHospital)
        ? moment(converDate(insuredPersonInformation?.dateOfHospital)).format()
        : null,
    },
    {
      name: "dateOfHospital",
      label: "Ngày vào viện ",
      placeholder: "Nhập thông tin",
      type: "date",
      time: true,
      disabledDate: (e) => {
        if (insuredPersonInformation.typeOfRisk == 1 && !!e) {
          return (
            moment(e?.format("YYYY/MM/DD")).diff(
              moment(moment().format("YYYY/MM/DD")),
              "days"
            ) > -1
          );
        }
        if (insuredPersonInformation.typeOfRisk == 2 && !!e) {
          return (
            moment(e.format("YYYY/MM/DD")).diff(
              moment(moment().format("YYYY/MM/DD")),
              "days"
            ) > 0
          );
        }
        return (
          moment(e?.format("YYYY/MM/DD")).diff(
            moment(moment().format("YYYY/MM/DD")),
            "days"
          ) > 0
        );
      },
      disabledTime: (e) => {
        const currentDay =
          moment(e?.format("YYYY/MM/DD")).diff(
            moment(moment().format("YYYY/MM/DD")),
            "days"
          ) == 0;

        if (currentDay) {
          const currentHours = moment().hour();
          const currentMinutes = moment().minute();
          const disabledMinutes =
            e?.hour() == moment().hour()
              ? () => range(currentMinutes, 60)
              : null;
          return {
            disabledHours: () => range(currentHours + 1, 24),
            disabledMinutes: disabledMinutes,
          };
        }
        return null;
      },
      value: formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
        ? moment(
            formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
          ).format()
        : null,
    },
    {
      name: "dateOutOfHospital",
      label: "Ngày ra viện ",
      placeholder: "Nhập thông tin",
      type: "date",
      time: true,
      disabledDate: (e) => {
        // if (insuredPersonInformation.typeOfRisk == 1) {
        //   return (
        //     moment(e.format("YYYY/MM/DD")).diff(
        //       moment(
        //         formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
        //       ).format("YYYY/MM/DD"),
        //       "days"
        //     ) < 1
        //   );
        // }

        // if (insuredPersonInformation.typeOfRisk == 2) {
        //   return (
        //     moment(e.format("YYYY/MM/DD")).diff(
        //       moment(
        //         moment(
        //           formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
        //         ).format("YYYY/MM/DD")
        //       ),
        //       "days"
        //     ) < 0
        //   );
        // }

        return insuredPersonInformation?.dateOfHospital
          ? moment(e.format("YYYY/MM/DD")).diff(
              moment(
                formatDate_Y_M_D(insuredPersonInformation?.dateOfHospital)
              ).format("YYYY/MM/DD"),
              "days"
            ) +
              2 <=
              1 ||
              moment(e.format("YYYY/MM/DD")).diff(
                moment().format("YYYY/MM/DD"),
                "days"
              ) >= 1
          : moment(e.format("YYYY/MM/DD")).diff(
              moment().format("YYYY/MM/DD"),
              "days"
            ) >= 1;
      },
      disabledTime: (e) => {
        const currentDay =
          moment(moment(e).format("YYYY/MM/DD")).diff(
            moment(moment().format("YYYY/MM/DD")),
            "days"
          ) == 0;

        if (insuredPersonInformation.typeOfRisk == 1) {
          return null;
        }

        if (currentDay) {
          const currentHours = moment().hour();
          const currentMinutes = moment().minute();
          const disabledMinutes =
            e.hour() == moment().hour()
              ? () => range(currentMinutes, 60)
              : null;
          return {
            disabledHours: () => range(currentHours + 1, 24),
            disabledMinutes: disabledMinutes,
          };
        }
        return null;
      },
      value: formatDate_Y_M_D(insuredPersonInformation?.dateOutOfHospital)
        ? moment(
            formatDate_Y_M_D(insuredPersonInformation?.dateOutOfHospital)
          ).format()
        : null,
    },
    {
      name: "insuredPrivateId",
      label: "CMND/CCCD/Hộ chiếu ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.insuredPrivateId || "",
    },
    {
      name: "insuredEmail",
      label: "Email ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.insuredEmail || "",
    },
    {
      name: "insuredAddress",
      label: "Địa chỉ ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.insuredAddress || "",
    },
    {
      name: "timeInHospital",
      label: "Tổng số ngày  điều trị ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.timeInHospital || "",
    },
    {
      name: "merchantTitle",
      label: "Nơi khai báo ",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.merchantTitle || "",
    },
  ];

  const benefitsInfor = [
    {
      name: "beneficiaryName",
      label: "Họ và tên",
      placeholder: "Nhập thông tin",
      type: "input",
      capitalize: true,
      value: insuredPersonInformation?.beneficiaryName || "",
    },
    {
      name: "beneficiaryBankAcountNumber",
      label: "Số tài khoản",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.beneficiaryBankAcountNumber || "",
    },
    {
      name: "relationshipWithInsuredPersonId",
      label: "Mối quan hệ",
      placeholder: "Nhập thông tin",
      type: "select",
      value: insuredPersonInformation?.relationshipWithInsuredPersonId || "",
      menu: [
        { value: 1, label: "Bản Thân" },
        { value: 2, label: "Người Thân" },
      ],
    },
    {
      name: "beneficiaryBankBranchName",
      label: "Chi nhánh",
      placeholder: "Nhập thông tin",
      type: "input",
      value: insuredPersonInformation?.beneficiaryBankBranchName || "",
    },
    {
      name: "beneficiaryBankTitle",
      label: "Ngân hàng",
      placeholder: "Nhập thông tin",
      type: "input",
      value:
        insuredPersonInformation?.beneficiaryBank ||
        insuredPersonInformation?.beneficiaryBankTitle ||
        "",
    },
  ];

  const render =
    name === "profileInformation" ? renderProfileInformation : benefitsInfor;

  return (
    <div>
      <Grid container spacing={3}>
        {render.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={6}>
            <div
              style={{
                color: "#4B4B4B",
                fontSize: "14px",
                paddingBottom: "6px",
              }}
            >
              {item.label}
            </div>
            {item.type === "input" && (
              <TextField
                autoComplete="new-password"
                fullWidth
                sx={{
                  "& input": {
                    textTransform: item.capitalize ? "capitalize" : "",
                  },
                }}
                value={item.value}
                onChange={(e) =>
                  dispatch({
                    type: "onChangTextTableUpdate",
                    value: e.target.value,
                    name: item.name,
                    typeInput: "text",
                  })
                }
                placeholder={item.placeholder}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {item.type === "select" && (
              <TextField
                autoComplete="new-password"
                fullWidth
                disabled={!!item.disabled}
                value={item.value}
                select
                onChange={(e) =>
                  dispatch({
                    type: "onChangTextTableUpdate",
                    value: e.target.value,
                    name: item.name,
                    typeInput: "select",
                  })
                }
                placeholder={item.placeholder}
                InputLabelProps={{ shrink: true }}
              >
                {(item.menu || []).map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {item.type === "date" && (
              <div className={classes.divDate}>
                {item.time ? (
                  <DatePicker
                    className="date-andt"
                    showTime
                    format="HH:mm, DD/MM/YYYY"
                    disabledDate={item?.disabledDate || null}
                    disabledTime={item?.disabledTime || null}
                    placeholder={item.placeholder}
                    value={item.value ? moment(item.value) : null}
                    onChange={(e) => {
                      dispatch({
                        type: "onChangTextTableUpdate",
                        value: !e ? null : e.format(),
                        name: item.name,
                        typeInput: "date",
                      });
                    }}
                  />
                ) : (
                  <DatePicker
                    className="date-andt"
                    format="DD/MM/YYYY"
                    placeholder={item.placeholder}
                    value={item.value ? moment(item.value) : null}
                    onChange={(e) => {
                      dispatch({
                        type: "onChangTextTableUpdate",
                        value: !e ? null : e.format(),
                        name: item.name,
                        typeInput: "date",
                      });
                    }}
                  />
                )}
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
