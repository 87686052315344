import React from "react";
import moment from "moment";
import { TableCell } from "@mui/material";

import styleCI from "./styleCI";
import CommonTable from "../../../../../../library/layout/commonTable";
import { converDate, formatDate_Y_M_D } from "../../utils";

export default function TableBeneficiary(props) {
  const classes = styleCI();
  const { state } = props;
  const { rowsBeneficiary } = state;

  return (
    <div>
      <CommonTable rows={rowsBeneficiary} columns={columns} classes={classes} />
    </div>
  );
}

const columns = [
  {
    label: "STT",
    headerClass: "stt",
    renderCell: ({ rowIdx }) => (
      <TableCell className="stt">{rowIdx + 1}</TableCell>
    ),
  },
  {
    label: "Họ và tên",
    headerClass: "fullname",
    renderCell: ({ rowIdx, item }) => (
      <TableCell className="" sx={{ textTransform: "capitalize" }}>
        {item.insuredName}
      </TableCell>
    ),
  },
  {
    label: "Ngày sinh",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {formatDate_Y_M_D(item.insuredDob)
          ? moment(formatDate_Y_M_D(item.insuredDob)).format("DD/MM/YYYY")
          : ""}
      </TableCell>
    ),
  },
  {
    label: "Giới tính",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {item.insuredGender === 1
          ? "Nam"
          : item.insuredGender === 0
          ? "Nữ"
          : ""}
      </TableCell>
    ),
  },
  {
    label: "Số CMND/ CCCD hộ chiếu",
    headerClass: "cmnd",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>{item?.insuredPrivateId || ""}</TableCell>
    ),
  },
  {
    label: "Mối quan hệ với người mua BH",
    headerClass: "relationship",
    renderCell: ({ rowIdx, item }) => (
      <TableCell>
        {item.relationshipWithInsuredPersonId == 1
          ? "Bản thân"
          : item.relationshipWithInsuredPersonName || ""}
      </TableCell>
    ),
  },
];
